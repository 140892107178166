import { MasterCardTravelFormData, MasterCardUserFormData, PaymentDetails } from 'components';
import { formatDate } from './date';

export const mapCustomerReceipt = (
	priceSummary: PriceSummary,
	pageModel: PageModel,
	travelFormData: MasterCardTravelFormData,
	userFormData: MasterCardUserFormData,
	paymentDetails: PaymentDetails,
	masterCardName: string,
	paymentId: string,
	brand: string,
) => {
	let currencyFormat: Intl.NumberFormat = Intl.NumberFormat('da-DK', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
	const feeDescription =
		priceSummary.insuranceFee.description + ' på ' + currencyFormat.format(priceSummary.insuranceFee.amount);

	currencyFormat = Intl.NumberFormat('da-DK', { minimumFractionDigits: 0, maximumFractionDigits: 0 });

	const addOnDescriptions: Array<string> = [];
	const addOnValues: Array<string> = [];
	const addonOrder = ['extendedTravel', 'extendedCancellation', 'travelCompensation', 'skiCoverage'];
	addonOrder.forEach((addonName) => {
		const priceDetail = priceSummary.priceDetails.filter((element) => element.insurancePriceType === addonName);
		if (priceDetail?.length > 0) {
			addOnDescriptions.push(priceDetail[0].description);
			addOnValues.push(currencyFormat.format(priceDetail[0].amount));
		}
	});

	let skiDestination = 'NA';
	if (pageModel.showSkiArea) {
		if (travelFormData.skiCoverageSelected && travelFormData.skiCoverageRegion) {
			skiDestination = travelFormData.skiCoverageRegion.toLocaleLowerCase() === 'europe' ? 'Europa' : 'Verden';
		}
	}

	const dateFormat = 'dd-mm-yyyy';
	const coInsuredNames: Array<string> = [];
	const coInsuredBirthDates: Array<string> = [];
	userFormData.coInsurers?.forEach((coInsured) => {
		coInsuredNames.push(coInsured.name);
		coInsuredBirthDates.push(formatDate(new Date(coInsured.birthDate), dateFormat));
	});

	const mailDetails = {
		//subject: 'Kvittering for køb af udvidet rejseforsikring', // TODO KLK From CMS??
		name: userFormData.firstName + ' ' + userFormData.lastName,
		address: userFormData.dawaAddressString,
		street: userFormData.address.roadName + ' ' + userFormData.address.roadNumber,
		zipcode: userFormData.address.postalCode + ' ' + userFormData.address.city,
		country: userFormData.address.country,
		phoneNo: 'NA', // ??
		email: userFormData.email,
		birthday: formatDate(new Date(userFormData.birthDate), dateFormat),
		insuredAmount: travelFormData.insuredCount,
		departureDate: formatDate(new Date(travelFormData.startDate), dateFormat),
		returnDate: formatDate(new Date(travelFormData.endDate), dateFormat),
		addOnDescriptions,
		addOnValues,
		hasCoInsured: coInsuredNames.length > 0 ? 'true' : 'false',
		coInsuredNames,
		coInsuredBirthDates,
		feeDescription,
		showSkiCoverage: pageModel.showSkiArea + '',
		skiDestination,
		skiCoverageSelected: travelFormData.skiCoverageSelected + '',
		total: currencyFormat.format(paymentDetails.amount),
		masterCardName,
		cardType: paymentDetails.cardName,
		orderNo: paymentDetails.orderNumber || paymentDetails.dibsTransactionNumber || paymentId,
		transactionNo: paymentDetails.dibsTransactionNumber || paymentDetails.id,
		Date: formatDate(new Date(), dateFormat),
		status: 'Gennemført',
		brand,
		token: '',
	};
	return mailDetails;
};
