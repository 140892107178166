import classNames from 'classnames';
import { Grid, Heading, LinkCard, PictureProps } from 'shared';
import styles from './MasterCardList.module.scss';

export interface MasterCardListProps {
	headline?: string;
	cards: TilesItem[];
}

export const MasterCardList: React.FC<MasterCardListProps> = ({ cards, headline }) => {
	return (
		<div className={classNames(styles.MasterCardList, headline && styles.MasterCardList___includesHeading)}>
			{headline && (
				<Heading headingLevel={'h2'} className={styles.MasterCardList_heading}>
					{headline}
				</Heading>
			)}
			<Grid wrap={true} className="u-grid--cell-padding30-sm u-grid--cell-padding50-md">
				{cards &&
					cards.map((result, index) => {
						const picture: PictureProps = {
							...result.content?.image,
							sizes: '26.3rem',
							properties: { altText: 'mastercard' },
						};
						return (
							<LinkCard
								key={index}
								image={picture}
								link={{ url: result?.content.canonicalUrl.path, name: result?.content?.headline }}
								className=" u-grid__cell--width-50@sm u-grid__cell--width-33@lg"
								desktopWidth="50"
							/>
						);
					})}
			</Grid>
		</div>
	);
};
