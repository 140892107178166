import { Dictionary } from 'dictionary';

export type ServicesDictionaries = 'LogOut' | 'Chat' | 'MitPrivatsikring' | 'MitCodan' | 'QuestionsCallCustomerService';

export const _Services = (dictionary: Partial<Dictionary>, item: ServicesDictionaries): string => {
	switch (item) {
		case 'LogOut':
			return dictionary.getValue('Services.LogOut', null, 'Log ud');
		case 'Chat':
			return dictionary.getValue('Services.Chat', null, 'Chat');
		case 'MitPrivatsikring':
			return dictionary.getValue('Services.MitPSK', null, 'Mit Privatsikring');
		case 'MitCodan':
			return dictionary.getValue('Services.MitCodan', null, 'Mit Codan');
		case 'QuestionsCallCustomerService':
			return dictionary.getValue(
				'Services.QuestionsCallCustomerService',
				null,
				'Har du spørgsmål eller ønsker hjælp så kontakt kundeservice på 70 11 17 07.',
			);
	}
};
