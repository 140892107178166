import { Container, Grid, GridCell } from 'shared';
import { Infobox } from './Infobox/Infobox';
import styles from './InfoboxModule.module.scss';

export interface InfoboxModuleProps {
	list: Infobox[];
}

export const InfoboxModule: React.FC<InfoboxModuleProps> = ({ list }) => {
	return (
		<Container>
			<Grid wrap className={styles.InfoboxModule}>
				{list.map((item, index) => {
					const { heading, text } = item ?? {};

					return (
						<GridCell key={index} desktopWidth="33" className={styles.InfoboxModule_item}>
							<Infobox heading={heading} text={text} />
						</GridCell>
					);
				})}
			</Grid>
		</Container>
	);
};
