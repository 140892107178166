import { Dictionary } from 'Online/context/Dictionary';

export type CompanyDictionaries = 'CompanyName';

export const _Company = (dictionary: Partial<Dictionary>, item: CompanyDictionaries): string => {
	switch (item) {
		case 'CompanyName':
			return dictionary.getValue('Company.CompanyName', null, 'Privatsikring A/S');
	}
};
