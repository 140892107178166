import classNames from 'classnames';
import { Container, Picture, PictureProps } from 'shared';
import styles from './FullGridWidthImage.module.scss';

export interface FullGridWidthImageProps {
	image: PictureProps;
	negativeTopMargin: boolean;
}

export const FullGridWidthImage: React.FC<FullGridWidthImageProps> = ({ image, negativeTopMargin }) => {
	return (
		<div
			className={classNames(
				styles.FullGridWidthImage,
				negativeTopMargin && styles.FullGridWidthImage___negativeTopMargin,
			)}
		>
			<Container>
				<Picture
					className={styles.FullGridWidthImage_asset}
					url={image.url}
					properties={image.properties}
					sizes="(min-width: 110rem) 100rem, 100vw"
					aspectRatio={0.55}
				/>
			</Container>
		</div>
	);
};
