import classNames from 'classnames';
import masterCardCallIcon from 'public/icons/pictogram_telephone_info.svg';
import masterCardCallIconInverted from 'public/icons/Pictogram_Telephone_inverted.svg';
import { useContext, useEffect, useState } from 'react';
import { AttentionCard, Container, DescriptionList, DescriptionListProps, PageLoader, Usabilla } from 'shared';
import { DictionaryContext } from '../../../../context/Dictionary';
import { _MasterCard, _Services, _Units } from '../../../../dictionaryItems';
import styles from './MasterCardReceipt.module.scss';
import { PageContext } from 'context/PageContext';
import { UsabillaWidgetIdReceipt } from '../../../../utilities/loadUsabillaScript';

export interface MasterCardReceiptProps {
	className?: string;
	governmentInsurance: string;
	masterCardAttentionIcon: Image;
	paymentDetailsList?: DescriptionListProps;
	priceDetailsList: DescriptionListProps;
	userDetailsList: DescriptionListProps;
	travelDetailsList: DescriptionListProps;
	spin: boolean;
}

export const MasterCardReceipt: React.FC<MasterCardReceiptProps> = ({
	className,
	governmentInsurance,
	masterCardAttentionIcon,
	paymentDetailsList,
	priceDetailsList,
	userDetailsList,
	travelDetailsList,
	spin,
}) => {
	const dictionary = useContext(DictionaryContext);
	const { theme } = useContext(PageContext);
	const [showAttentionCard, setShowAttentionCard] = useState(true);
	const [notifyUserComplete, setNotifyUserComplete] = useState(false);
	const [callIcon, setCallIcon] = useState(null);

	useEffect(() => {
		paymentDetailsList != null && setNotifyUserComplete(true);
	}, [paymentDetailsList]);

	useEffect(() => {
		switch (theme) {
			case 'Sydbank-theme':
			case 'Dark-theme Sydbank-theme':
				setShowAttentionCard(false);
				break;
			case 'Dark-theme':
				setCallIcon(masterCardCallIconInverted);
				setShowAttentionCard(true);
				break;
			default:
				setCallIcon(masterCardCallIcon);
				setShowAttentionCard(true);
				break;
		}
	});

	{
		return !notifyUserComplete || spin ? (
			<Container width="Medium">
				<PageLoader />
				<Usabilla step="5" usabillaWidgetId={UsabillaWidgetIdReceipt} category={'mastercard_sales_' + theme} />
			</Container>
		) : (
			<div className={classNames(styles.MasterCardReceipt, className)}>
				<Container width="Medium">
					{userDetailsList && (
						<DescriptionList className={styles.MasterCardReceipt_list} {...userDetailsList} />
					)}
					{travelDetailsList && (
						<DescriptionList className={styles.MasterCardReceipt_list} {...travelDetailsList} />
					)}
					{priceDetailsList && (
						<DescriptionList
							className={classNames(styles.MasterCardReceipt_priceDetailsList)}
							{...priceDetailsList}
						/>
					)}
					<AttentionCard
						className={styles.MasterCardReceipt_disclaimer}
						conditionsText={
							_MasterCard(dictionary, 'PriceIncludesInsuranceToGovernment') +
							` ${governmentInsurance} ` +
							_Units(dictionary, 'DanishCrowns')
						}
						image={masterCardAttentionIcon}
						cardType={{ type: 'disclaimer' }}
						cardSize={{ size: 'small' }}
					/>
				</Container>
				<Container width="Medium" className={classNames(styles.MasterCardReceipt_container)}>
					<DescriptionList className={styles.MasterCardReceipt_list} {...paymentDetailsList} />
				</Container>

				{showAttentionCard && (
					<AttentionCard
						className={styles.MasterCardReceipt_info}
						cardType={{ type: 'info' }}
						cardSize={{ size: 'medium' }}
						image={{ url: callIcon }}
						conditionsText={_Services(dictionary, 'QuestionsCallCustomerService')}
					/>
				)}

				<Usabilla step="5" usabillaWidgetId={UsabillaWidgetIdReceipt} category={'mastercard_sales_' + theme} />
			</div>
		);
	}
};
