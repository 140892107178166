import { MasterCardList } from './MasterCardList';

// export interface MasterCardListAPIProps {
// 	heading?: string;
// 	rootFolder: BasePage;
// }
export interface MasterCardListAPIProps {
	headline?: string;
	tiles?: {
		items: TilesItem[];
	};
}

export const MasterCardListAPI: React.FC<MasterCardListAPIProps> = ({ headline = '', tiles }) => {
	//const { data: MasterCards } = useSWR([rootFolder?.id], getMasterCardsById);

	// useEffect(() => {
	// 	if (!MasterCards?.pageSearch?.pages) return;
	// }, [MasterCards]);

	// if (!MasterCards) return null;
	if (!tiles) return null;

	return <MasterCardList cards={tiles?.items} headline={headline} />;
};
