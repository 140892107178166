import { PaymentDetails } from 'components';
import { RootState, store } from '../store/store';
import { getApiInstance } from './getApiInstance';
import { getPartnerByCardType } from './getPartnerByCadType';

interface MasterCardPaymentConfig {
	paymentId: string;
	hostedPaymentPageUrl: string;
}

const api = getApiInstance();

export const getMasterCardPaymentUrl = async (
	masterCardType: MasterCardType,
	returnUrl: string,
	termsURL: string,
	cancelURL: string,
): Promise<MasterCardPaymentConfig | ErrorResponse> => {
	const state = store.getState() as RootState;
	const { userFormData, travelFormData, config } = state.mastercard ?? {};
	const cardConfig = config.data.cardInfo.find((cardConfig) => cardConfig?.name === masterCardType);
	const price =
		Number(travelFormData.price) > cardConfig?.publicCancellationCoverage?.maxSum &&
		cardConfig.publicCancellationCoverage?.maxSum !== null
			? cardConfig?.publicCancellationCoverage?.maxSum
			: travelFormData.price;
	const priceTravelCompensation =
		Number(travelFormData.priceTravelCompensation) > cardConfig?.publicTravelCompensationCoverage?.maxSum &&
		cardConfig?.publicTravelCompensationCoverage?.maxSum !== null
			? cardConfig?.publicTravelCompensationCoverage?.maxSum
			: travelFormData.priceTravelCompensation;

	try {
		const partner = getPartnerByCardType(masterCardType);
		const payment = await api.post(`/api/v1/travelsales/CreatePayment/${partner}`, {
			cardName: masterCardType,
			cancelURL,
			returnUrl,
			termsURL,
			userDetails: {
				reference: masterCardType,
				email: userFormData.email,
				birthday: userFormData.birthDate,
				privatePerson: {
					firstname: userFormData.firstName,
					lastname: userFormData.lastName,
				},
				shippingaddress: {
					addressLine1: userFormData.address.roadName,
					addressLine2: userFormData.address.roadNumber,
					receiverLine: userFormData.firstName + ' ' + userFormData.lastName,
					postalCode: userFormData.address.postalCode,
					city: userFormData.address.city,
					country: userFormData?.foreignCountry || 'DNK',
				},
			},
			insuranceDetails: {
				numberOfInsured: Number(travelFormData.insuredCount),
				coInsurers: userFormData.coInsurers,
				startDate: travelFormData.startDate,
				endDate: travelFormData.endDate,
				extendedTravelCoverageSelected:
					travelFormData.extendedTravelCoverageSelected === undefined
						? true
						: travelFormData.extendedTravelCoverageSelected,
				skiCoverageSelected: travelFormData?.skiCoverageSelected,
				skiCoverageRegion: travelFormData?.skiCoverageRegion,
				isExtension: travelFormData.isExtension,
				price: price ? price : '0',
				priceTravelCompensation: priceTravelCompensation ? priceTravelCompensation : '0',
			},
		});
		const paymentResponse: MasterCardPaymentConfig | ErrorResponse = await payment.data;
		return paymentResponse;
	} catch (error) {
		console.log('ERROR: ', error);

		throw new Error(error);
	}
};

export const notifyMasterCardUser = async (
	masterCardType: MasterCardType,
	paymentId: string,
	useCmsEmailCustomerReceipt: boolean,
): Promise<PaymentDetails | ErrorResponse> => {
	try {
		const partner = getPartnerByCardType(masterCardType);
		const receiptResponse = await api.post(
			`/api/v1/travelsales/NotifyUser/${partner}/${paymentId}/${useCmsEmailCustomerReceipt}`,
		);
		const paymentDetails: PaymentDetails | ErrorResponse = await receiptResponse.data;
		const res = paymentDetails as PaymentDetails;
		if (res?.dibsTransactionNumber) {
			res.id = res.dibsTransactionNumber;
		}
		return res;
	} catch (error) {
		throw new Error(error);
	}
};
