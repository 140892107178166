import classNames from 'classnames';
import { useContext } from 'react';
import { Container, Factbox, FactboxItemProps, FormfieldCheckbox, FormfieldToggle, Heading, RichText } from 'shared';
import { DictionaryContext } from '../../../../../context/Dictionary';
import { _MasterCard } from '../../../../../dictionaryItems';
import styles from './MasterCardConditions.module.scss';

export interface MasterCardConditionsProps {
	id?: string;
	conditionsAccepted: (accepted: boolean) => void;
	heading: string;
	text: string;
	paymentProviders: FactboxItemProps[];
	className?: string;
	labelText?: string;
	theme?: string;
}

export const MasterCardConditions: React.FC<MasterCardConditionsProps> = ({
	id,
	conditionsAccepted,
	heading,
	text,
	paymentProviders,
	className,
	labelText,
	theme,
}) => {
	const dictionary = useContext(DictionaryContext);
	return (
		<div id={id} className={classNames(styles.MasterCardConditions, className)}>
			<Container width="Medium">
				<Heading className={styles.MasterCardConditions_heading} headingLevel="h3">
					{heading}
				</Heading>
				<RichText className={styles.MasterCardConditions_text} content={text} />
				{theme !== 'Sydbank-theme' && theme !== 'Dark-theme Sydbank-theme' ? (
					<FormfieldToggle
						className={styles.MasterCardConditions_toggle}
						label={{ active: labelText || _MasterCard(dictionary, 'IAcceptTheConditions') }}
						name="conditions-accepted"
						id="toggle-conditions"
						callback={(checked) => conditionsAccepted(checked)}
					/>
				) : (
					<FormfieldCheckbox
						className={styles.MasterCardTravel_checkbox}
						id="conditions-accepted"
						name="conditions-accepted"
						label={labelText || _MasterCard(dictionary, 'IAcceptTheConditions')}
						callback={(checked) => conditionsAccepted(checked)}
					/>
				)}
				<Factbox factboxItems={paymentProviders} style="vanilla" />
			</Container>
		</div>
	);
};
