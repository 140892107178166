import classNames from 'classnames';
import { Component, ComponentProps } from 'components/Component';
import { Container, Heading } from 'shared';
import styles from './USPBlock.module.scss';

type USPBlockProps = {
	heading: string;
	items: Array<ComponentProps>;
	settings?: {
		properties: {
			highlightFirstItem: boolean;
			style?: 'Light' | 'Dark';
		};
	};
};
export const USPBlock: React.VFC<USPBlockProps> = ({ heading, items, settings }) => {
	const { style = 'Light', highlightFirstItem } = settings?.properties || {};

	return (
		<Container>
			<div
				className={classNames(styles.USPBlock, styles[`USPBlock___style${style}`], {
					[styles.USPBlock___highlightFirstItem]: highlightFirstItem,
				})}
			>
				<Heading headingLevel="h2" style="xl">
					{heading}
				</Heading>
				{items.length > 0 && (
					<div className={styles.USPBlock_itemsContainer}>
						{items.length > 0 && (
							<div className={styles.USPBlock_itemGrid}>
								{items.map((item, i) => (
									<Component key={i} {...item} />
								))}
							</div>
						)}
					</div>
				)}
			</div>
		</Container>
	);
};
