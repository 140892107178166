import classNames from 'classnames';
import styles from './LinkButton.module.scss';

export interface LinkButtonProps {
	children: React.ReactNode;
	state?: 'inactive';
	size?: 'small';
	style?: 'primary' | 'secondary' | 'inactive' | 'sm';
	title?: string;
	className?: string;
	url?: string;
	target?: string;
	onClick?: (e: React.MouseEvent<HTMLElement>) => any;
	download?: boolean;
}

export const LinkButton: React.FC<LinkButtonProps> = ({
	children,
	size,
	style,
	title,
	className,
	state,
	url,
	target,
	onClick,
	download,
}) => (
	<a
		href={url}
		title={title}
		className={classNames(
			styles.LinkButton,
			styles[`LinkButton___${size}`],
			styles[`LinkButton___${style}`],
			styles[`LinkButton___${state}`],
			className,
		)}
		target={target}
		onClick={(e) => {
			onClick && onClick(e);
		}}
		download={download}
	>
		{children}
	</a>
);
