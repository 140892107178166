import classNames from 'classnames';
import styles from './Spinner.module.scss';

export interface SpinnerProps {
	style?: 'large' | 'small' | 'slow' | 'error';
	type?: 'circle' | 'dotted' | 'dottedCircle';
	color?: 'primary';
}

export const Spinner: React.FC<SpinnerProps> = ({ style, type = 'circle', color }) => (
	<>
		<div
			className={classNames(
				styles.Spinner,
				styles[`Spinner___${style}`],
				styles[`Spinner_${type}`],
				styles[`Spinner___${color}`],
			)}
			aria-hidden="true"
		>
			{type == 'dotted' && (
				<div className={classNames(styles.Spinner_dotContainer)}>
					<div className={classNames(styles.Spinner_dotFirst)} />
					<div className={classNames(styles.Spinner_dotSecond)} />
					<div className={classNames(styles.Spinner_dotThird)} />
				</div>
			)}
			{type == 'dottedCircle' && (
				<div className={classNames(styles.Spinner_dotContainer, color)}>
					{/* eslint-disable-next-line prettier/prettier */}
					<div>
						<div></div>
					</div>
					<div>
						<div></div>
					</div>
					<div>
						<div></div>
					</div>
					<div>
						<div></div>
					</div>
					<div>
						<div></div>
					</div>
					<div>
						<div></div>
					</div>
					<div>
						<div></div>
					</div>
					<div>
						<div></div>
					</div>
				</div>
			)}
		</div>
		<div className={classNames(styles.Spinner_screenReader, styles.Spinner_loadingMsg)} role="alert">
			loading...
		</div>
	</>
);
