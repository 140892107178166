import React from 'react';
import { setPageMetaData } from 'services';

export interface PageProps {
	//pageSettings: ComponentProps[];
	children?: React.ReactNode;
}

export class Page {
	private _pageModel: PageModel;

	public constructor(pageType: PageModel) {
		this._pageModel = pageType;
		setPageMetaData(this._pageModel);
	}

	public get pageModel(): PageModel {
		return this._pageModel;
	}

	public get footer(): FooterReference {
		return this._pageModel?.frontendConfiguration?.footerReference;
	}

	public get header(): HeaderReference {
		return this._pageModel?.frontendConfiguration?.headerReference;
	}

	public get brand(): string {
		const brand = this._pageModel?.frontendConfiguration?.globalDictionary?.find(
			(keyValue: KeyValue) => keyValue.key.toLowerCase() === 'brand',
		);
		if (brand?.value) {
			return brand.value.toUpperCase();
		}
		return 'ALMBRAND';
	}

	public get pageType(): string {
		return this._pageModel?.contentType;
	}

	public get headline(): string {
		return this._pageModel?.headline;
	}

	public get pageHeaderContent(): PageHeader {
		return this._pageModel?.pageHeader;
	}

	public get pageSectionsContent(): PageSections {
		return this._pageModel?.pageSections;
	}

	public get masterCardType(): MasterCardType {
		return this._pageModel?.masterCardType;
	}

	public get bannerImage(): BannerImage {
		return this._pageModel?.bannerImage;
	}

	public get theme(): string {
		// const masterCardType = this._pageModel?.masterCardType;
		// let theme: ThemeName = 'Privatsikring';

		// switch (masterCardType) {
		// 	case 'WEM':
		// 	case 'WEMN':
		// 		theme = 'Dark';
		// 		break;
		// }

		// return theme;

		return this._pageModel?.theme;
	}

	public get termsUrl(): TermsURL {
		return this._pageModel?.termsUrl;
	}

	public get url(): CanonicalURL {
		return this._pageModel?.canonicalUrl;
	}
	public get yourTravelTitle(): string {
		return this._pageModel?.yourTravelTitle;
	}

	public get yourTravelHeaderText(): string {
		return this._pageModel?.yourTravelHeaderText;
	}

	public get travelPeriodTitle(): string {
		return this._pageModel?.travelPeriodTitle;
	}

	public get travelPeriodText(): string {
		return this._pageModel?.travelPeriodText;
	}

	public get overviewFields(): OverviewFields[] {
		return this._pageModel?.overviewFields;
	}

	public get useCmsEmailCustomerReceipt(): boolean {
		return !!this._pageModel?.useCmsEmailCustomerReceipt;
	}

	/*
	public get name(): string {
		return this._pageType?.page?.name;
	}

	public get settings(): ComponentProps[] {
		return this.pageType?.page?.properties?.pageSettings;
	}

	public get pageType(): PageTypeProps {
		return this._pageType;
	}

	public get id(): number {
		return this._pageType?.page?.id;
	}

	public get url(): string {
		return this._pageType?.page?.url;
	}
	public get theme(): ThemeName {
		return this.pageType?.theme?.[0]?.properties?.themeName;
	}
	public get authentication(): boolean {
		return this.pageType?.authentication?.[0]?.properties?.authenticationRequired;
	}
		public getSettings<T>(key: string): T {
		return this.settings?.find((e) => e.documentType === key)?.content?.properties;
	}
*/
}

export const PageContext = React.createContext<Partial<Page>>(new Page(null));
