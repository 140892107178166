import Cookies from 'js-cookie';

const flowName = window.location.pathname.split('/')[1];

export const saveStateToCookie = async (state: any) => {
	const cookieValue = JSON.stringify(state);
	const minutesUntilExpire = 10;
	const expires = minutesUntilExpire / (24 * 60); // Convert minutes to days
	await Cookies.set(`redux-${flowName}-state`, cookieValue, { expires });
};

export const loadStateFromCookie = () => {
	const cookieValue = Cookies.get(`redux-${flowName}-state`);
	if (cookieValue) {
		return JSON.parse(cookieValue);
	}
	return undefined;
};

export const removeStateFromCookie = () => {
	Cookies.remove(`redux-${flowName}-state`);
};
