export const getPartnerByCardType = (masterCardType: MasterCardType): Partner => {
	switch (masterCardType) {
		case 'MSBN':
		case 'MGN':
		case 'MPN':
		case 'MPBN':
		case 'WEMN':
			return 'Nykredit';

		case 'MSGS':
		case 'MSGPS':
		case 'MSPS':
		case 'MSUS':
		case 'MSPBPS':
		case 'MSPBES':
			return 'Sydbank';

		default:
			return 'Lopi';
	}
};
