import { ColumnFooter, Footer, Header } from 'components';
import { PageContext } from 'context/PageContext';
import { useContext } from 'react';
import { keyboardFocus } from 'utilities';
import styles from './Layout.module.scss';

export const Layout: React.FC<any> = (props) => {
	const pageContext = useContext(PageContext);
	const theme = pageContext.theme ? pageContext.theme : 'Default';
	/*
	const [navigationItems, setNavigationItems] = useState(null);
	const dispatch = useDispatch();
	const siteContext = useContext(SiteContext);
	const externalNavigation = siteContext.getSettings('externalNavigation') ?? {};
	const utilityIcons: UtilityIcons = siteContext.getSettings('utilityIcons') ?? {};
	const siteAreas: SiteAreas = siteContext.getSettings('siteAreas');
	const currentSiteArea = useSelector(selectCurrentSiteAreaName);

	const pageType = pageContext?.pageType;
	const rootPageExists = pageType?.root?.header.find((page) => page.id === pageType?.rootPage?.id);

	if (!rootPageExists) {
		pageType?.root?.header.unshift(pageType?.rootPage);
	}

	const userLinks = siteContext.getUserNavigationItems(pageType?.page, pageType?.root?.header);

	useEffect(() => {
		if (!siteAreas?.siteAreaItems) {
			dispatch(clearSiteAreas());
			return;
		}
		if (!currentSiteArea) {
			dispatch(setCurrentSiteArea(siteAreas.siteAreaItems[0].name));
			return;
		}
		dispatch(setSiteAreas(siteAreas.siteAreaItems));
	}, [dispatch, currentSiteArea, siteAreas]);

	useEffect(() => {
		let navItems: NavigationItem[];

		if (!currentSiteArea) navItems = siteContext.getNavigationItems(pageContext?.pageType?.page);
		if (currentSiteArea) navItems = siteContext.getNavigationItems(pageContext?.pageType?.page, currentSiteArea);

		setNavigationItems(navItems);
	}, [currentSiteArea, siteContext, pageContext?.pageType?.page]);
*/
	keyboardFocus();

	const footerType = pageContext?.footer?.contentType;
	return (
		<div className={styles.Layout}>
			{pageContext?.header && <Header theme={theme} />}
			{props.children}
			{footerType === 'FooterBlock' && <Footer />}
			{footerType === 'ColumnFooterBlock' && <ColumnFooter />}
		</div>
	);
};
