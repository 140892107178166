import _ from 'lodash';
import * as yup from 'yup';

const validDawaCategories = ['A', 'B'];
const getDawa = async (value: string) => {
	const dawaResponse = await fetch(`https://api.dataforsyningen.dk/datavask/adresser?betegnelse=${value}`);
	if (!dawaResponse.ok) {
		console.error('Dawa request failed');
	}
	const jsonResponse = (await dawaResponse.json()) as {
		kategori: 'A' | 'B' | 'C';
	};

	return jsonResponse;
};

const debouncedApi = _.debounce(getDawa, 300, {
	leading: true,
	trailing: true,
});

yup.addMethod<yup.StringSchema>(yup.string, 'validDawaAddress', function (message) {
	return this.test('dawa-test', 'Address is not a valid DAWA address', async function (value) {
		const { path, createError } = this;
		if (!value) {
			return true;
		}

		const categories = await debouncedApi(value);

		return validDawaCategories.includes(categories.kategori)
			? true
			: createError({
					message: message || 'Address is not a valid DAWA address',
					path: path,
			  });
	});
});

export default yup;
