import { ReactComponent as DownloadIcon } from 'public/icons/icon_download.svg';
import { VFC } from 'react';
import { Container, Heading, LinkButton } from 'shared';
import styles from './Download.module.scss';

type DownloadProps = {
	heading: string;
	files: Array<{
		label: string;
		link: Link;
	}>;
};
export const Download: VFC<DownloadProps> = (props) => {
	const { heading } = props;
	const files = props.files.map((file) => file);
	return (
		<Container>
			<div className={styles.Download}>
				<div className={styles.Download_HeadingAndButtonsContainer}>
					<Heading headingLevel="h2" style="xl">
						{heading}
					</Heading>
					<div className={styles.Download_Buttons}>
						{files.map((file, i) => (
							<div key={i}>
								<LinkButton
									style="secondary"
									className={styles.Download_Button}
									url={file.link.url}
									target="_blank"
								>
									<DownloadIcon width="16" height="16" className={styles.Download_Icon} />
									{file.label}
								</LinkButton>
							</div>
						))}
					</div>
				</div>
			</div>
		</Container>
	);
};
