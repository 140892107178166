import { Dictionary } from 'Online/context/Dictionary';

type UnitsDictionaries = Unit | 'Person' | 'Persons';

export const _Units = (dictionary: Partial<Dictionary>, item: UnitsDictionaries): string => {
	switch (item) {
		case 'AbbreviatedMonth':
			return dictionary.getValue('Units.AbbreviatedMonth', null, 'md.');

		case 'Adults':
			return dictionary.getValue('Units.Adults', null, 'voksne');

		case 'Children':
			return dictionary.getValue('Units.Children', null, 'børn');

		case 'DanishCrowns':
			return dictionary.getValue('Units.DanishCrowns', null, 'kr.');

		case 'Days':
			return dictionary.getValue('Units.Days', null, 'dage');

		case 'Kilometer':
			return dictionary.getValue('Units.Kilometer', null, 'km');

		case 'Number':
			return dictionary.getValue('Units.Number', null, 'nummer');

		case 'NumberAbbreviation':
			return dictionary.getValue('Units.NumberAbbreviation', null, 'nr.');

		case 'Person':
			return dictionary.getValue('Units.Person', null, 'person');

		case 'Persons':
			return dictionary.getValue('Units.Persons', null, 'personer');

		case 'Policy':
			return dictionary.getValue('Units.Policy', null, 'Police');

		case 'Year':
			return dictionary.getValue('Units.Year', null, 'år');

		default:
			return '';
	}
};
