import classNames from 'classnames';
import styles from './Main.module.scss';

export interface MainProps {
	className?: string;
	header?: React.ReactNode;
	children: React.ReactNode;
}

export const Main: React.FC<MainProps> = ({ className, header, children }) => {
	return (
		<main id="main" className={classNames(styles.Main, className)}>
			{header && <header>{header}</header>}
			<article>{children}</article>
		</main>
	);
};
