import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { MasterCardCta } from 'components';
import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm, useFormState } from 'react-hook-form';
import {
	Container,
	Form,
	FormfieldCheckbox,
	FormfieldGroup,
	FormfieldSelect,
	FormfieldString,
	FormfieldTypeheadDawa,
	Grid,
	GridCell,
	Usabilla,
} from 'shared';
import { DictionaryContext } from '../../../../context/Dictionary';
import { _Actions, _MasterCard, _Placeholder, _User } from '../../../../dictionaryItems';
import yup from '../../../../utilities/validations';
import styles from './MasterCardUserForm.module.scss';
import { ISOCountryList } from '../../../../constants/ISOCountryList';
import { MasterCardConditions } from '../MasterCardSummary/MasterCardConditions';
import { PageContext } from 'context/PageContext';
import { UsabillaWidgetIdFlow } from '../../../../utilities/loadUsabillaScript';

type coInsurer = {
	name: string;
	birthDate: string;
};

type Address = {
	roadName: string;
	roadNumber: string;
	postalCode: string;
	city: string;
	country?: string;
};

export interface MasterCardUserFormData {
	firstName?: string;
	lastName: string;
	dawaAddressString: string;
	address: Address;
	foreignStreetName: string;
	foreignStreetNumber: string;
	foreignPostalCode: number;
	foreignCity: string;
	foreignCountry: string;
	email: string;
	birthDate: string;
	coInsurers: coInsurer[];
	foreignAddress: boolean;
	[key: string]: string | number | coInsurer[] | Address | boolean;
}

export interface MasterCardUserFormProps extends PageModel {
	callbackContinue: (data: MasterCardUserFormData) => void;
	callbackPrevious: () => void;
	className?: string;
	insuredCount: number;
	userFormData?: MasterCardUserFormData;
	cardTypeGroup: MasterCardTypeGroup;
}

type Option = {
	text: string;
	value: string;
	selected?: boolean;
	disabled?: boolean;
};

export const MasterCardUserForm: React.FC<MasterCardUserFormProps> = ({
	callbackContinue,
	callbackPrevious,
	className,
	insuredCount,
	userFormData,
	cardTypeGroup,
	personInfoConditionsHeadline,
	personInfoConditionsText,
	personInfoFields,
	theme,
}) => {
	const [formData, setFormData] = useState<MasterCardUserFormData>(userFormData);
	const [conditionsAccepted, setConditionsAccepted] = useState(false);
	const [ISOCountryOptions, setISOCountryOptions] = useState<Option[]>([]);
	const [isForeignAddress, setIsForeignAddress] = useState(userFormData?.foreignAddress);
	const schema = yup.object().shape({
		firstName: yup.string().required('Angiv fornavn'),
		lastName: yup.string().required('Angiv efternavn'),
		dawaAddressString: yup
			.string()
			.concat(
				!isForeignAddress && yup.string().required('Angiv adresse').validDawaAddress('Angiv en gyldig adresse'),
			),
		email: yup.string().email('Angiv e-mailadresse').required('Angiv en gyldig e-mailadresse'),
		birthDate: yup.string().required('Angiv fødselsdato'),
		foreignCountry: yup.string().concat(isForeignAddress && yup.string().required('Angiv land')),
	});
	const dictionary = useContext(DictionaryContext);
	const { register, handleSubmit, control, watch } = useForm<MasterCardUserFormData>({
		resolver: yupResolver(schema),
		shouldUnregister: true,
	});
	const [additionalAddressValues, setAdditionalAddressValues] = useState<Address>();

	const [errorSummary, setErrorSummary] = useState(0);

	const getAdditionalAddressValues = (data: Address) => {
		setAdditionalAddressValues(data);
	};

	useEffect(() => {
		const options: Option[] = [
			{
				text: 'F.eks. Sverige',
				value: '',
				selected: true,
			},
		];
		ISOCountryList.forEach((country) => {
			options.push({
				text: country.name,
				value: country.alpha3,
			});
		});

		setISOCountryOptions(options);
	}, []);

	useEffect(() => {
		const subscription = watch((value) => {
			setFormData(value);
		});

		return () => subscription.unsubscribe();
	}, [watch]);

	const onSubmit: SubmitHandler<MasterCardUserFormData> = (data) => {
		const coInsuredFields = Object.keys(data).filter((key) => key.includes('coInsured'));
		const coInsuredFieldsLength = coInsuredFields.length / 2;

		const coInsurers: coInsurer[] = [];

		for (let i = 0; i < coInsuredFieldsLength; i++) {
			coInsurers.push({
				name: data[`coInsuredName${i}`].toString(),
				birthDate: data[`coInsuredBirthDate${i}`].toString(),
			});
		}

		if (isForeignAddress) {
			data.address = {
				city: data?.foreignCity,
				postalCode: data?.foreignPostalCode.toString(),
				roadName: data?.foreignStreetName,
				roadNumber: data?.foreignStreetNumber,
				country: data?.foreignCountry,
			};
		} else {
			data.address = {
				city: additionalAddressValues?.city,
				postalCode: additionalAddressValues?.postalCode,
				roadName: additionalAddressValues?.roadName,
				roadNumber: additionalAddressValues?.roadNumber,
			};
		}

		data.coInsurers = coInsurers;
		callbackContinue(data);
	};
	const { errors } = useFormState({
		control,
	});

	const onError = (data) => {
		const totalErrors = Object.keys(data).length;
		setErrorSummary(totalErrors);
	};

	return (
		<div className={classNames(styles.MasterCardUserForm, className)}>
			<Form onSubmit={handleSubmit(onSubmit, onError)} errorSummary={errorSummary}>
				<Container width={theme !== 'Dark-theme Sydbank-theme' ? 'Medium' : null}>
					<FormfieldGroup
						className={styles.MasterCardUserForm_wrapper}
						id="userform"
						name="PolicyOwner"
						label={_MasterCard(dictionary, 'PolicyOwner')}
					>
						<Grid
							className={classNames(styles.MasterCardUserForm_grid, {
								['u-grid--cell-padding20']: theme !== 'Dark-theme Sydbank-theme',
								[styles.MasterCardUserForm_grid_darkSydbank]: theme === 'Dark-theme Sydbank-theme',
							})}
							wrap
						>
							<GridCell desktopWidth="50">
								<FormfieldString
									id="first-name"
									name="firstName"
									type="text"
									label={_User(dictionary, 'FirstName')}
									state={errors.firstName ? { hasError: true, required: true } : { required: true }}
									placeholder={_Placeholder(dictionary, 'EnterFirstName')}
									register={register}
									defaultValue={userFormData?.firstName}
									errorMessage={errors.firstName?.message}
								/>
							</GridCell>
							<GridCell desktopWidth="50">
								<FormfieldString
									id="last-name"
									name="lastName"
									type="text"
									label={_User(dictionary, 'LastName')}
									state={errors.lastName ? { hasError: true, required: true } : { required: true }}
									placeholder={_Placeholder(dictionary, 'EnterLastName')}
									register={register}
									defaultValue={userFormData?.lastName}
									errorMessage={errors.lastName?.message}
								/>
							</GridCell>

							<GridCell desktopWidth="50">
								<FormfieldString
									id="email"
									name="email"
									type="email"
									label={_User(dictionary, 'Email')}
									state={errors.email ? { hasError: true, required: true } : { required: true }}
									placeholder={_Placeholder(dictionary, 'EnterYourEmail')}
									register={register}
									defaultValue={userFormData?.email}
									errorMessage={errors.email?.message}
								/>
							</GridCell>
							<GridCell desktopWidth="50">
								<FormfieldString
									id="birth-date"
									name="birthDate"
									type="date"
									className={classNames(styles.MasterCardUserForm_dateField, {
										[styles.hasValue]: formData?.birthDate.length,
									})}
									label={_User(dictionary, 'BirthDate')}
									state={errors.birthDate ? { hasError: true, required: true } : { required: true }}
									placeholder={_Placeholder(dictionary, 'EnterYourBirthday')}
									register={register}
									defaultValue={userFormData?.birthDate}
									errorMessage={errors.birthDate?.message}
								/>
							</GridCell>

							<GridCell desktopWidth="100">
								<FormfieldCheckbox
									id="foreignAddress"
									name="foreignAddress"
									register={register}
									className={classNames({
										[styles.MasterCardUserForm_checkbox]: theme === 'Dark-theme Sydbank-theme',
									})}
									label={
										(personInfoFields &&
											personInfoFields.find((x) => x.fieldId === 'foreignAddress')?.label) ||
										'Jeg har en udenlandsk adresse (Foreign adress)'
									}
									callback={setIsForeignAddress}
									defaultChecked={userFormData?.foreignAddress}
								/>
							</GridCell>

							{!isForeignAddress ? (
								<GridCell desktopWidth="50">
									<FormfieldTypeheadDawa
										id="dawaAddressString"
										name="dawaAddressString"
										label={'Adresse' || _User(dictionary, 'Address')}
										placeholder={_Placeholder(dictionary, 'EnterAddress')}
										register={register}
										getAddress={getAdditionalAddressValues}
										state={
											errors.dawaAddressString
												? { hasError: true, required: true }
												: { required: true }
										}
										defaultValue={userFormData?.dawaAddressString}
										errorMessage={errors.dawaAddressString?.message}
									/>
								</GridCell>
							) : (
								<>
									<GridCell desktopWidth="50">
										<FormfieldString
											id="street-name"
											name="foreignStreetName"
											type="text"
											label={_User(dictionary, 'RoadName')}
											state={
												errors.foreignStreetName
													? { hasError: true, required: true }
													: { required: true }
											}
											placeholder={_Placeholder(dictionary, 'EnterYourStreetName')}
											register={register}
											defaultValue={userFormData?.foreignStreetName}
										/>
									</GridCell>
									<GridCell desktopWidth="50">
										<FormfieldString
											id="road-number"
											name="foreignStreetNumber"
											type="text"
											label={_User(dictionary, 'RoadNumber')}
											state={
												errors.foreignStreetNumber
													? { hasError: true, required: true }
													: { required: true }
											}
											placeholder={_Placeholder(dictionary, 'EnterYourHouseNumber')}
											register={register}
											defaultValue={userFormData?.foreignStreetNumber}
										/>
									</GridCell>
									<GridCell desktopWidth="50">
										<FormfieldString
											id="postal-code"
											name="foreignPostalCode"
											type="text"
											label={_User(dictionary, 'PostalCode')}
											state={
												errors.foreignPostalCode
													? { hasError: true, required: true }
													: { required: true }
											}
											pattern="\d*"
											placeholder={_Placeholder(dictionary, 'EnterYourZipCode')}
											register={register}
											defaultValue={
												userFormData?.foreignPostalCode &&
												String(userFormData.foreignPostalCode)
											}
										/>
									</GridCell>
									<GridCell desktopWidth="50">
										<FormfieldString
											id="city"
											name="foreignCity"
											type="text"
											label={_User(dictionary, 'City')}
											state={
												errors.foreignCity
													? { hasError: true, required: true }
													: { required: true }
											}
											placeholder={_Placeholder(dictionary, 'EnterCityName')}
											register={register}
											defaultValue={userFormData?.foreignCity}
											pattern="[A-Z,a-z,æøå,ÆØÅ, ]+$"
										/>
									</GridCell>
									<GridCell desktopWidth="50">
										<FormfieldSelect
											className={styles.MasterCardTravel_countField}
											id="foreignCountry"
											name="foreignCountry"
											label="Land"
											defaultValue={
												userFormData?.foreignCountry ? userFormData?.foreignCountry : ''
											}
											options={ISOCountryOptions}
											register={register}
											state={
												errors.foreignCountry
													? { hasError: true, required: true }
													: { required: true }
											}
											errorMessage={errors.foreignCountry?.message}
										/>
									</GridCell>
								</>
							)}
						</Grid>
					</FormfieldGroup>

					{theme !== 'Sydbank-theme' && theme !== 'Dark-theme Sydbank-theme' && insuredCount > 1 && (
						<FormfieldGroup
							className={styles.MasterCardUserForm_wrapper}
							id="co-insured"
							name="coInsured"
							label={_MasterCard(dictionary, 'CoInsured')}
							closeCtaText={_Actions(dictionary, 'Close')}
							helpCtaText={
								cardTypeGroup !== 'worldelite'
									? _MasterCard(dictionary, 'CoInsuredHelpText')
									: _MasterCard(dictionary, 'CoInsuredHelpTextWorldelite')
							}
						>
							{Array.from(Array(insuredCount - 1), (e, i) => {
								return (
									<Grid
										className={classNames(
											styles.MasterCardUserForm_grid,
											styles.MasterCardUserForm_grid___sibling,
											'u-grid--cell-padding20',
										)}
										wrap
										key={i + 'co-insured'}
									>
										<GridCell desktopWidth="50">
											<FormfieldString
												id={`co-insured-name-${i}`}
												name={`coInsuredName${i}`}
												type="text"
												label={_User(dictionary, 'Name')}
												register={register}
												state={
													errors[`coInsuredName${i}`]
														? { hasError: true, required: true }
														: { required: true }
												}
												defaultValue={
													userFormData?.[`coInsuredName${i}`]
														? String(userFormData[`coInsuredName${i}`])
														: null
												}
											/>
										</GridCell>
										<GridCell desktopWidth="50">
											<FormfieldString
												id={`co-insured-birth-date-${i}`}
												name={`coInsuredBirthDate${i}`}
												type="date"
												label={_User(dictionary, 'BirthDate')}
												register={register}
												state={
													errors[`coInsuredBirthDate${i}`]
														? { hasError: true, required: true }
														: { required: true }
												}
												defaultValue={
													userFormData?.[`coInsuredBirthDate${i}`]
														? String(userFormData[`coInsuredBirthDate${i}`])
														: null
												}
											/>
										</GridCell>
									</Grid>
								);
							})}
						</FormfieldGroup>
					)}

					{(theme === 'Sydbank-theme' || theme === 'Dark-theme Sydbank-theme') && (
						<FormfieldGroup id="cpr-consent" name="CPRconsent">
							<MasterCardConditions
								id="personConsent"
								conditionsAccepted={(accepted) => setConditionsAccepted(accepted)}
								heading={personInfoConditionsHeadline || 'Givv dit samtyckke'}
								text={
									personInfoConditionsText ||
									`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`
								}
								labelText={
									personInfoFields &&
									personInfoFields.find((x) => x.fieldId === 'personConsent')?.label
								}
								paymentProviders={[]}
								theme={theme}
							/>
						</FormfieldGroup>
					)}

					<MasterCardCta
						className={styles.MasterCardUserForm_cta}
						callbackPrevious={callbackPrevious}
						continueText={_Actions(dictionary, 'Next')}
						continueDisabled={
							theme === 'Sydbank-theme' || theme === 'Dark-theme Sydbank-theme'
								? !conditionsAccepted
								: false
						}
						submit
					/>
				</Container>
			</Form>
			<Usabilla step="2" usabillaWidgetId={UsabillaWidgetIdFlow} category={'mastercard_sales_' + theme} />
		</div>
	);
};
