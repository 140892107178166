import classNames from 'classnames';
import { Heading, RichText } from 'shared';
//import { ReactComponent as LogoLightCodan } from 'Shared/public/logos/codan-logo-white.svg';
//import { ReactComponent as LogoDarkCodan } from 'Shared/public/logos/codan-logo.svg';
//import { ReactComponent as LogoLightESK } from 'public/logos/es-logo-negative.svg';
//import { ReactComponent as LogoDarkESK } from 'public/logos/es-logo.svg';
//import { ReactComponent as LogoLightColorPSK } from 'Shared/public/logos/ps-logo-white-color.svg';
//import { ReactComponent as LogoLightPSK } from 'Shared/public/logos/ps-logo-white.svg';
//import { ReactComponent as LogoDarkPSK } from 'Shared/public/logos/ps-logo.svg';
import styles from './Logo.module.scss';

export interface LogoProps {
	className?: string;
	description?: string;
	style: 'Light' | 'Dark';
	logoImageLight?: Image;
	logoImageDark?: Image;
	enforceDarkStyleForDesktop?: boolean;
	margined?: boolean;
	noMargins?: boolean;
	logoUrl?: string;
}

export const Logo: React.FC<LogoProps> = ({
	className,
	description,
	style,
	enforceDarkStyleForDesktop,
	margined,
	noMargins,
	logoImageLight,
	logoImageDark,
	logoUrl,
}) => {
	// TODO klk hack while mit.privatsikring.dk is being developed in Optimizely as https://core02/privatsikring.dk
	if (logoUrl && logoUrl.startsWith('https://core02.privatsikring')) {
		logoUrl = logoUrl.replaceAll('https://core02', 'https://mit');
	}

	const dark: boolean = style === 'Dark';
	return (
		<div
			className={classNames(
				styles.Logo,
				styles[`Logo___${style}`],
				enforceDarkStyleForDesktop && styles.Logo___enforceDarkStyleForDesktop,
				margined && styles.Logo___margined,
				className,
			)}
		>
			{(logoImageLight || logoImageDark) && (
				<a className={styles.Logo_link} href={logoUrl || '#'}>
					<Heading headingLevel="h6" className="u-visually-hidden">
						test
					</Heading>
					<div className={classNames(styles.Logo_asset, styles.Logo_asset___Esk)}>
						{logoImageDark && (
							<img
								className={classNames(styles.Logo_icon, styles.Logo_icon___Dark)}
								src={logoImageDark.url}
								alt={logoImageDark.properties?.altText}
							/>
						)}
						{logoImageLight && (
							<img
								className={classNames(styles.Logo_icon, styles.Logo_icon___Light)}
								src={logoImageLight?.url}
								alt={logoImageLight?.properties?.altText}
							/>
						)}
					</div>
					<div className={classNames(styles.Logo_asset, styles.Logo_asset___Psk)}>
						{dark && logoImageDark && (
							<img
								className={classNames(styles.Logo_icon, styles.Logo_icon___Dark)}
								src={logoImageDark.url}
								alt={logoImageDark.properties?.altText}
							/>
						)}
						{!dark && logoImageLight && (
							<img
								className={classNames(styles.Logo_icon, styles.Logo_icon___Light)}
								src={logoImageLight?.url}
								alt={logoImageLight?.properties?.altText}
							/>
						)}
					</div>
					<div className={classNames(styles.Logo_asset, styles.Logo_asset___WorldElite)}>
						{dark && logoImageDark && (
							<img
								className={classNames(styles.Logo_icon, styles.Logo_icon___Dark)}
								src={logoImageDark.url}
								alt={logoImageDark.properties?.altText}
							/>
						)}
						{!dark && logoImageLight && (
							<img
								className={classNames(styles.Logo_icon, styles.Logo_icon___Light)}
								src={logoImageLight?.url}
								alt={logoImageLight?.properties?.altText}
							/>
						)}
					</div>
					<div className={classNames(styles.Logo_asset, styles.Logo_asset___Codan)}>
						{logoImageDark && (
							<img
								className={classNames(styles.Logo_icon, styles.Logo_icon___Dark)}
								src={logoImageDark.url}
								alt={logoImageDark.properties?.altText}
							/>
						)}
						{logoImageLight && (
							<img
								className={classNames(styles.Logo_icon, styles.Logo_icon___Light)}
								src={logoImageLight?.url}
								alt={logoImageLight?.properties?.altText}
							/>
						)}
					</div>
					<div className={classNames(styles.Logo_asset, styles.Logo_asset___Syd)}>
						{logoImageDark && (
							<img
								className={classNames(styles.Logo_icon, styles.Logo_icon___Dark)}
								src={logoImageDark.url}
								alt={logoImageDark.properties?.altText}
							/>
						)}
						{!logoImageDark && logoImageLight && (
							<img
								className={classNames(styles.Logo_icon, styles.Logo_icon___Light)}
								src={logoImageLight?.url}
								alt={logoImageLight?.properties?.altText}
							/>
						)}
					</div>
				</a>
			)}

			{description && (
				<RichText
					className={classNames(styles.Logo_description, noMargins && styles.Logo_description___noMargin)}
					style="large"
					content={description}
				/>
			)}
		</div>
	);
};
