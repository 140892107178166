import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { Container, Heading, RichText } from 'shared';
import styles from './Accordion.module.scss';
import { AccordionItem, AccordionItemProps } from './AccordionItem';
import { PageContext } from 'context/PageContext';

export interface AccordionProps {
	// allowMultiple?: boolean;
	accordionItems?: {
		items?: Array<{
			content: AccordionItemProps;
		}>;
	};

	className?: string;
	title?: string;
	description?: string;
	containerWidth: ContainerWidth;
}

export const Accordion: React.FC<AccordionProps> = ({
	accordionItems,
	className,
	description,
	title,
	containerWidth,
}) => {
	const [openId, setOpenId] = useState(null);

	const handleToggling = (id: string, state: boolean) => {
		if (openId === id) return setOpenId(false);
		if (state) return setOpenId(false);
		setOpenId(id);
	};

	const handleIsOpen = (id: string) => {
		if (openId === null) return null;
		return openId === id ? true : false;
	};

	return (
		<div className={classNames(styles.Accordion, className)}>
			<Container width={containerWidth}>
				{title && (
					<Heading className={styles.Accordion_heading} headingLevel="h2" style="sm">
						{title}
					</Heading>
				)}
				{description && <RichText className={styles.Accordion_description} content={description} />}
				<div className={styles.Accordion_items}>
					{accordionItems?.items?.map((accordionItemContent, index) => {
						const accItem: AccordionItemProps = accordionItemContent.content;
						const id = `accordion${accItem.title}-${index}`;
						return (
							<AccordionItem
								{...accItem}
								key={id}
								id={id}
								className={styles.Accordion_trigger}
								isOpen={handleIsOpen(id)}
								toggle={(id, state) => handleToggling(id, state)}
							/>
						);
					})}
				</div>
			</Container>
		</div>
	);
};
