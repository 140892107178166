import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { debounce, GetImageUrl, GetSrcSetString, imageSizesConfig, inView } from 'utilities';
import styles from './Picture.module.scss';

export interface PictureProps extends Image {
	sizes?: string;
	lazyload?: boolean;
	objectFitFallback?: boolean;
	isCover?: boolean;
	aspectRatio?: number;
	className?: string;
	wait?: number;
}

export const Picture: React.FC<PictureProps> = ({
	isCover = true,
	sizes = '100vw',
	url,
	lazyload = true,
	objectFitFallback = true,
	properties,
	aspectRatio,
	className,
}) => {
	const { thumbnailSize, fallbackSize } = imageSizesConfig.images;
	const imageFocalPoint = properties?.imageFocalPoint;

	const thumbnailImageUrl = GetImageUrl({ url, width: thumbnailSize });
	const thumbnailImageUrlWebp = GetImageUrl({
		url,
		width: thumbnailSize,
		format: 'webp',
	});

	const [src, setSrc] = useState(thumbnailImageUrl);
	const [imgSrcSet, setImgSrcSet] = useState(thumbnailImageUrl);
	const [sourceSrcSet, setSourceSrcSet] = useState(thumbnailImageUrlWebp);
	const [isFallback, setIsFallback] = useState(false);
	const [pictureDimensions, setPictureDimensions] = useState({
		height: 0,
		width: 0,
		aspectRatio: aspectRatio,
	});

	const pictureRef = useRef(null);

	useEffect(() => {
		if (objectFitFallback) objectFit();
		if (lazyload) inView({ elm: pictureRef.current, callback: reveal });
		if (!lazyload) reveal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pictureDimensions]);

	const reveal = () => {
		const srcSetString = GetSrcSetString({ url, imageFocalPoint, aspectRatio });
		const srcSetStringWebp = GetSrcSetString({
			url,
			imageFocalPoint,
			format: 'webp',
			aspectRatio,
		});
		const srcString = GetImageUrl({ url, width: fallbackSize });

		setImgSrcSet(srcSetString);
		setSourceSrcSet(srcSetStringWebp);
		setSrc(srcString);
	};

	const objectFit = () => {
		// If the useragent doesn't support ObjectFit (IE), we need to
		// add fallback image to the picture element
		const supportObjectFit = 'objectFit' in document.documentElement.style;

		if (!supportObjectFit) setIsFallback(true);
	};

	const getPictureDimensions = () => {
		setPictureDimensions({
			...pictureDimensions,
			height: pictureRef.current.offsetHeight,
			width: pictureRef.current.offsetWidth,
			aspectRatio: pictureRef.current.offsetHeight / pictureRef.current.offsetWidth,
		});
	};

	useEffect(() => {
		getPictureDimensions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const getPictureDimensionsEfficient = debounce(() => {
			getPictureDimensions();
		});

		window.addEventListener('resize', getPictureDimensionsEfficient);

		return () => {
			window.removeEventListener('resize', getPictureDimensionsEfficient);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<picture
			ref={pictureRef}
			className={classNames(
				styles.Picture,
				{ [styles.Picture___cover]: isCover },
				{ [styles.isFallback]: isFallback },
				className,
			)}
			style={isFallback ? { backgroundImage: `url(${src})` } : {}}
		>
			{!isFallback && (
				<>
					<source sizes={sizes} type="image/webp" srcSet={sourceSrcSet} />
					<img
						src={src}
						alt={properties?.altText}
						className={styles.Picture_asset}
						srcSet={imgSrcSet}
						sizes={sizes}
					/>
				</>
			)}
		</picture>
	);
};
