// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const debounce = (func, wait = 500, immediate = false) => {
	let timeout;

	return function executedFunction() {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const context = this;
		// eslint-disable-next-line prefer-rest-params
		const args = arguments;

		const later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};

		const callNow = immediate && !timeout;

		clearTimeout(timeout);

		timeout = setTimeout(later, wait);

		if (callNow) func.apply(context, args);
	};
};
