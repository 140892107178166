import classNames from 'classnames';
import { RichText } from 'shared';
import styles from './ArticleText.module.scss';

export interface ArticleTextProps {
	content?: string;
	className?: string;
}

export const ArticleText: React.FC<ArticleTextProps> = ({ className, content }) => {
	return (
		<div className="u-container--slim">
			<RichText className={classNames(styles.ArticleText, className)} content={content} />
		</div>
	);
};
