// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const keyboardFocus = () => {
	const body = window.document.body;
	body.addEventListener(
		'keyup',
		() => {
			body.classList.add('usingKeyboard');
		},
		false,
	);
	body.addEventListener(
		'click',
		() => {
			body.classList.remove('usingKeyboard');
		},
		false,
	);
	body.addEventListener(
		'touchstart',
		() => {
			body.classList.remove('usingKeyboard');
		},
		false,
	);
};
