import classNames from 'classnames';
import { Heading } from 'shared';
import { toKebabCase } from '../../../../utilities';
import styles from './DescriptionList.module.scss';
import { DescriptionListItem, DescriptionListItemProps } from './DescriptionListItem';
import React from 'react';

export interface DescriptionListProps {
	className?: string;
	heading: string;
	descriptionList: DescriptionListItemProps[];
	horizontalLine?: boolean;
}

export const DescriptionList: React.FC<DescriptionListProps> = ({
	className,
	heading,
	descriptionList,
	horizontalLine = false,
}) => {
	const ariaId = toKebabCase(heading);

	return (
		<div className={classNames(styles.DescriptionList, className)}>
			<Heading className={styles.DescriptionList_heading} headingLevel="h3" id={ariaId}>
				{heading}
			</Heading>
			<dl aria-describedby={ariaId}>
				{descriptionList.map((item, index) => {
					return (
						<React.Fragment key={index}>
							<DescriptionListItem className={styles.DescriptionList_item} {...item} />
							{index === descriptionList.length - 2 && horizontalLine && <hr />}
						</React.Fragment>
					);
				})}
			</dl>
		</div>
	);
};
