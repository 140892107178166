import { configureStore, Middleware } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import { rootReducer } from './reducers';
import storage from './storage'; // session storage
import { loadStateFromCookie, saveStateToCookie } from '../utilities/cookieUtils';

const persistConfig = {
	key: 'root',
	version: 1,
	storage,
};

const preloadedState = {
	...loadStateFromCookie(),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const persistenceMiddleware: Middleware = (store) => (next) => (action) => {
	const result = next(action);

	const state = store.getState();

	const { mastercard } = state;

	saveStateToCookie({ mastercard });

	return result;
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const store = configureStore({
	reducer: persistedReducer,
	preloadedState: preloadedState,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(persistenceMiddleware),
});
