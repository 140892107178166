import classNames from 'classnames';
import { Logo } from 'components';
import { PageContext } from 'context/PageContext';
import { useContext, useState } from 'react';
import { Container, FooterCard, FooterNavigation, Grid, GridCell, Heading } from 'shared';
import styles from './Footer.module.scss';

export interface FooterProps {
	className?: string;
	heading?: string;
}

export const Footer: React.FC<FooterProps> = ({ className, heading }) => {
	const [secondaryFooterVisible, setSecondaryFooterVisible] = useState(false);
	const pageContext = useContext(PageContext);
	const footer = pageContext.footer;
	// const theme = pageContext.theme;
	// useEffect(() => {
	// 	const secondaryExists = components?.some((component: ComponentProps) => {
	// 		return component?.settings?.properties?.primary === false;
	// 	});
	// 	setSecondaryFooterVisible(secondaryExists);
	// }, [components]);

	return (
		<footer className={classNames(styles.Footer, className)} role="contentinfo">
			<Heading headingLevel="h6" id="primary-footerNavigation-heading" className="u-visually-hidden">
				{footer.heading}
			</Heading>

			<div className={styles.Footer_primary}>
				<Container width="Large">
					<Grid className="u-grid--cell-h-no-padding" wrap>
						<GridCell desktopWidth="50" className={styles.Footer_cell}>
							<Logo
								style={'Light'}
								logoImageLight={Object.keys(footer.logoImage).length > 0 && footer.logoImage}
								noMargins={Object.keys(footer.logoImage).length === 0 && true}
								description={footer.heading}
								logoUrl={footer.logoUrl?.url}
							/>
						</GridCell>
						<GridCell desktopWidth="50" className={styles.Footer_cell}>
							{footer.linkColumns ? (
								<FooterNavigation
									footerNavigationItems={footer.linkColumns}
									socialMediaLinks={footer.socialMediaLinks}
								/>
							) : (
								<FooterCard links={footer.policyLinks} text={footer.companyInformation} />
							)}
						</GridCell>
					</Grid>
				</Container>
			</div>

			{footer.linkColumns && (
				<div className={styles.Footer_secondary}>
					<Container width="Large">
						<FooterCard links={footer.policyLinks} text={footer.companyInformation} />
					</Container>
				</div>
			)}
		</footer>
	);
};
