import classNames from 'classnames';
import { ChangeEvent } from 'react';
import styles from './FormfieldToggle.module.scss';

export interface FormfieldToggleProps {
	callback?: (event: boolean) => void;
	className?: string;
	id: string;
	label: {
		active: string;
		inactive?: string;
	};
	name?: string;
	state?: {
		disabled?: boolean;
		hasError?: boolean;
	};
}

export const FormfieldToggle: React.FC<FormfieldToggleProps> = ({ callback, className, state, id, label, name }) => {
	const { disabled, hasError } = state ?? {};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		callback((event.target as HTMLInputElement).checked);
	};
	return (
		<div
			className={classNames(
				styles.FormfieldToggle,
				className,
				{ [styles.hasError]: hasError },
				{ [styles.isDisabled]: disabled },
			)}
		>
			<input
				className={styles.FormfieldToggle_control}
				id={id}
				type="checkbox"
				disabled={disabled}
				name={name}
				onChange={callback && handleChange}
			/>
			<label className={styles.FormfieldToggle_label} htmlFor={id}>
				<span className={styles.FormfieldToggle_labelActive}>{label.active}</span>
				<span className={styles.FormfieldToggle_labelInactive}>
					{label?.inactive ? label.inactive : label.active}
				</span>
			</label>
		</div>
	);
};
