import { Dictionary } from 'Online/context/Dictionary';

export type TimeDictionaries = 'Date' | 'Day' | 'ForTheSpecifiedTimePeriod' | 'Time' | 'Today' | 'Tomorrow';

export const _Time = (dictionary: Partial<Dictionary>, item: TimeDictionaries): string => {
	switch (item) {
		case 'Date':
			return dictionary.getValue('Time.Date', null, 'Dato');
		case 'Day':
			return dictionary.getValue('Time.Day', null, 'Dag');
		case 'ForTheSpecifiedTimePeriod':
			return dictionary.getValue('Time.ForTheSpecifiedTimePeriod', null, 'i det angivne tidsrum.');
		case 'Time':
			return dictionary.getValue('Time.Time', null, 'Tidspunkt');
		case 'Today':
			return dictionary.getValue('Time.Today', null, 'i dag');
		case 'Tomorrow':
			return dictionary.getValue('Time.Tomorrow', null, 'i morgen');
	}
};
