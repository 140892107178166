import { Dictionary } from 'Online/context/Dictionary';

export type PriceDictionaries =
	| 'Amount'
	| 'IncludingTaxAndCharges'
	| 'PricePerMonth'
	| 'YourMonthlyPrice'
	| 'TotalPricePerMonth'
	| 'TotalPrice'
	| 'Total';

export const _Price = (dictionary: Partial<Dictionary>, item: PriceDictionaries): string => {
	switch (item) {
		case 'Amount':
			return dictionary.getValue('Price.Amount', null, 'Beløb');
		case 'PricePerMonth':
			return dictionary.getValue('Price.PricePerMonth', null, 'Pris pr måned');
		case 'YourMonthlyPrice':
			return dictionary.getValue('Price.YourMonthlyPrice', null, 'Din pris pr. måned, inkl. skatter og afgifter');
		case 'TotalPricePerMonth':
			return dictionary.getValue('Price.TotalPricePerMonth', null, 'Samlet pris pr. måned');
		case 'TotalPrice':
			return dictionary.getValue('Price.TotalPrice', null, 'Samlet pris');
		case 'Total':
			return dictionary.getValue('Price.Total', null, 'Total');
	}
};
