import { Dictionary } from 'Online/context/Dictionary';

export type CustomerDictionaries =
	| 'BankAdvisorEmail'
	| 'CustomerName'
	| 'CustomerNumber'
	| 'CustomerPhoneNumber'
	| 'CustomerMail'
	| 'CustomerBank'
	| 'CustomerNoBank'
	| 'CustomerLocalDepartment'
	| 'CustomerCallSubject'
	| 'CustomerConsentToBeCalled'
	| 'PSKCustomerNumber';

export const _Customer = (dictionary: Partial<Dictionary>, item: CustomerDictionaries): string => {
	switch (item) {
		case 'BankAdvisorEmail':
			return dictionary.getValue('Customer.BankAdvisorEmail', null, 'Bankrådgivers mail');
		case 'CustomerName':
			return dictionary.getValue('Customer.CustomerName', null, 'Kundens navn');
		case 'CustomerNumber':
			return dictionary.getValue('Customer.CustomerNumber', null, 'Kundenr.');
		case 'CustomerPhoneNumber':
			return dictionary.getValue('Customer.CustomerPhoneNumber', null, 'Kundens telefonnummer');
		case 'CustomerBank':
			return dictionary.getValue('Customer.WhatIsCustomerBank', null, 'Hvilken bank har kunden?');
		case 'CustomerNoBank':
			return dictionary.getValue('Customer.CustomerNoBank', null, 'Ingen bank');
		case 'CustomerLocalDepartment':
			return dictionary.getValue('Customer.LocalDepartment', null, 'Vælg lokalafdeling');
		case 'CustomerCallSubject':
			return dictionary.getValue('Customer.WhatIsCustomerCallSubject', null, 'Hvad ønsker kunden at tale om?');
		case 'CustomerMail':
			return dictionary.getValue('Customer.CustomerMail', null, 'Kundens mail');
		case 'CustomerConsentToBeCalled':
			return dictionary.getValue(
				'Customer.CustomerConsentToBeCalled',
				null,
				'Jeg bekræfter, at ovenstående kunde har givet samtykke til at modtage et opkald fra',
			);
		case 'PSKCustomerNumber':
			return dictionary.getValue('Customer.PSKCustomerNumber', null, 'Privatsikring kundenr.');
	}
};
