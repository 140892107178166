import classNames from 'classnames';
import { VFC } from 'react';
import { Picture } from 'shared';
import styles from './TestimonialCard.module.scss';

export type TestimonialCardProps = {
	authorPicture?: Image;
	authorName?: string;
	authorOccupation?: string;
	quote?: string;
	settings?: {
		properties: {
			background?: boolean;
			padding?: boolean;
			large?: boolean;
			style?: 'Light' | 'Dark';
		};
	};
};
export const TestimonialCard: VFC<TestimonialCardProps> = ({
	authorPicture,
	authorName,
	authorOccupation,
	quote,
	settings,
}) => {
	const { background, padding, style = 'Light', large = false } = settings?.properties || {};

	return (
		<div
			className={classNames(styles.Testimonial, styles[`Testimonial___style${style}`], {
				[styles.Testimonial___background]: background,
				[styles.Testimonial___padding]: padding,
				[styles.Testimonial___large]: large,
			})}
		>
			{authorPicture || authorName || authorOccupation ? (
				<div className={styles.Testimonial_personalInformation}>
					{authorPicture && (
						<Picture
							className={styles.Testimonial_profilePicture}
							url={authorPicture.url}
							properties={authorPicture.properties}
						/>
					)}
					{authorName || authorOccupation ? (
						<div className={styles.Testimonial_nameAndOccupation}>
							{authorName ? <span>{authorName}</span> : null}
							{authorOccupation ? (
								<span className={styles.Testimonial_occupation}>{authorOccupation}</span>
							) : null}
						</div>
					) : null}
				</div>
			) : null}
			{quote && <p className={styles.Testimonial_quote}>{quote}</p>}
		</div>
	);
};
