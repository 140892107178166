import classNames from 'classnames';

export interface ThemeContext {
	themeName: string;
	children?: React.ReactNode;
}

export const ThemeContext: React.FC<ThemeContext> = (props) => {
	let themeName = props?.themeName;
	if (themeName === 'Dark-theme') {
		// TODO This information should come from Optimizely
		themeName += ' Dark-theme--masterCardPage';
	}

	//Original supporting page themes: <div className={classNames(`${props?.themeName}-theme`, `${props?.themeName}-theme--${props?.documentType}`)}>
	return <div className={classNames(`${themeName}`)}>{props?.children}</div>;
};
