import classNames from 'classnames';

export interface GridProps {
	children: React.ReactNode;
	wrap?: boolean;
	container?: boolean;
	nogutter?: boolean;
	center?: boolean;
	className?: string;
}

export const Grid: React.FC<GridProps> = ({ wrap, container, nogutter, center, className, children }) => (
	<div
		className={classNames(
			'u-grid',
			{ 'u-grid--wrap': wrap },
			{ 'u-grid--container': container },
			{ 'u-grid--no-gutter': nogutter },
			{ 'u-grid--center': center },
			className,
		)}
	>
		{children}
	</div>
);
