import classNames from 'classnames';
import { Icon, Image, RichText } from 'shared';
import styles from './AttentionCard.module.scss';

interface AttentionCardType {
	type?: 'disclaimer' | 'error' | 'info' | 'pending' | 'success';
}
interface AttentionCardSize {
	size?: 'default' | 'small' | 'medium' | 'large';
}

export interface AttentionCardProps {
	className?: string;
	image?: Image;
	header?: string;
	conditionsText: string;
	cardType: AttentionCardType;
	cardSize?: AttentionCardSize;
}

export const AttentionCard: React.FC<AttentionCardProps> = ({
	className,
	conditionsText,
	image = { properties: { altText: '' }, url: 'icons/icon-error.svg' },
	cardType = { type: 'error' },
	cardSize = { size: 'small' },
}) => {
	const { type } = typeof cardType === 'string' ? { type: cardType } : cardType;
	const { size } = typeof cardSize === 'string' ? { size: cardSize } : cardSize;

	return (
		<div
			role={type === 'error' ? 'alert' : null}
			className={classNames(
				styles.AttentionCard,
				styles[`AttentionCard___${type}`],
				styles[`AttentionCard___${size}`],
				className,
			)}
		>
			<div className={styles.AttentionCard_wrapper}>
				<div className={styles.AttentionCard_container}>
					{image && Object.keys(image).length > 0 && (
						<Icon className={styles.AttentionCard_icon} size={type === 'disclaimer' ? 'lg' : '2xl'}>
							<Image
								className={styles.AttentionCard_iconImage}
								sizes="3rem"
								isCover={false}
								isCenter={true}
								url={image.url}
								properties={image.properties}
							/>
						</Icon>
					)}

					<RichText className={styles.AttentionCard_text} content={conditionsText} />
				</div>
			</div>
		</div>
	);
};
