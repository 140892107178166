import { Dictionary } from 'Online/context/Dictionary';

export type UserDictionaries =
	| 'Name'
	| 'FirstName'
	| 'LastName'
	| 'Address'
	| 'RoadName'
	| 'RoadNumber'
	| 'PostalCode'
	| 'PostalCodeAndCiy'
	| 'City'
	| 'Email'
	| 'BirthDate';

export const _User = (dictionary: Partial<Dictionary>, item: UserDictionaries): string => {
	switch (item) {
		case 'Name':
			return dictionary.getValue('User.Name', null, 'Navn');
		case 'FirstName':
			return dictionary.getValue('User.FirstName', null, 'Fornavn');
		case 'LastName':
			return dictionary.getValue('User.LastName', null, 'Efternavn');
		case 'Address':
			return dictionary.getValue(
				'User.Address',
				null,
				`Adresse (hvis du har en udenlandsk adresse bedes du kontakte kundeservice på tlf. +45 70 11 17 07)`,
			);
		case 'RoadName':
			return dictionary.getValue('User.RoadName', null, 'Vejnavn');
		case 'RoadNumber':
			return dictionary.getValue('User.RoadNumber', null, 'Nr.');
		case 'PostalCode':
			return dictionary.getValue('User.PostalCode', null, 'Postnummer');
		case 'PostalCodeAndCiy':
			return dictionary.getValue('User.PostalCodeAndCiy', null, 'Postnummer og by');
		case 'City':
			return dictionary.getValue('User.City', null, 'By');
		case 'Email':
			return dictionary.getValue('User.Email', null, 'Email');
		case 'BirthDate':
			return dictionary.getValue('User.BirthDate', null, 'Fødselsdato');
	}
};
