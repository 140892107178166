export const setPageMetaData = (model: any) => {
	try {
		if (!model) {
			return;
		}
		document.title = model.metaTitle || document.title;

		const metaData = [
			{ type: 'description', value: model.metaDescription || '', property: 'name' },
			{ type: 'keywords', value: model.metaKeywords || '', property: 'name' },
			{
				type: 'og:image',
				value: model.ogImage && model.ogImage.url ? model.ogImage.url : '',
				property: 'property',
			},
			{ type: 'og:description', value: model.ogDescription || '', property: 'property' },
			{ type: 'og:title', value: model.ogTitle || '', property: 'property' },
		];
		metaData.forEach((meta) => {
			const metaTag = document.querySelector(`meta[${meta.property}="${meta.type}"]`);

			if (!metaTag) {
				let tag = document.createElement('meta');
				tag.setAttribute(meta.property, meta.type);
				tag.setAttribute('content', meta.value);
				document.head.appendChild(tag);
			} else {
				metaTag.setAttribute('content', meta.value);
			}
		});
	} catch (e) {}
};
