import React from 'react';
import styles from './ProductOverview.module.scss';
import { ProductOverviewItem, ProductOverviewItemProps } from './ProductOverviewItem';

type ProductOverviewProps = {
	linkAsButton?: boolean;
	pricePerMonth?: boolean;
	items: ProductOverviewItemProps[];
};
export const ProductOverview: React.FC<ProductOverviewProps> = (props) => {
	const { linkAsButton, pricePerMonth, items } = props;

	return (
		<div className={styles.ProductOverview}>
			{items.map((item, index) => {
				const { link, ...restProps } = item;

				const productOverviewItem = (
					<ProductOverviewItem
						link={link}
						linkAsButton={linkAsButton}
						pricePerMonth={pricePerMonth}
						{...restProps}
					/>
				);

				return (
					<div className={styles.ProductOverview_item} key={index}>
						{!linkAsButton ? (
							<a className={styles.ProductOverview_linkWrapper} href={link?.url}>
								{productOverviewItem}
							</a>
						) : (
							productOverviewItem
						)}
					</div>
				);
			})}
		</div>
	);
};
