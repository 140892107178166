import { Dictionary } from 'Online/context/Dictionary';

export type ErrorsDictionaries =
	| 'ChooseAtLeastOneField'
	| 'EmailConfirmationError'
	| 'FetchError'
	| 'LinkToPolicyError'
	| 'RememberToFillInConsent'
	| 'IssuesWithOneOfOurSystems'
	| 'SomethingWentWrong'
	| 'ThisFieldShouldBeFilled'
	| 'TryAgainLater';

export const _Errors = (dictionary: Partial<Dictionary>, item: ErrorsDictionaries): string => {
	switch (item) {
		case 'ChooseAtLeastOneField':
			return dictionary.getValue('Error.ChooseAtLeastOneField', null, 'Vælg mindst et af felterne');
		case 'EmailConfirmationError':
			return dictionary.getValue(
				'Error.EmailConfirmationError',
				null,
				'Fejl ved afsendelse af bekræftelse på email.',
			);
		case 'FetchError':
			return dictionary.getValue(
				'Error.FetchError',
				null,
				'Fejl ved indlæsning af data. Prøv at genopfriske browseren.',
			);
		case 'LinkToPolicyError':
			return dictionary.getValue(
				'Error.LinkToPolicyError',
				null,
				'Det lader til at der er problemer med linket til policen i øjeblikket.',
			);
		case 'RememberToFillInConsent':
			return dictionary.getValue('Error.RememberToFillInConsent', null, 'Husk at udfylde samtykke');
		case 'SomethingWentWrong':
			return dictionary.getValue('Error.SomethingWentWrong', null, 'Ups... der gik noget galt');

		case 'ThisFieldShouldBeFilled':
			return dictionary.getValue('Error.ThisFieldShouldBeFilled', null, 'Dette felt skal udfyldes');

		case 'IssuesWithOneOfOurSystems':
			return dictionary.getValue(
				'Error.IssuesWithOneOfOurSystems',
				null,
				'Der er i øjeblikket problemer med et af vores systemer. Prøv igen senere.',
			);
		case 'TryAgainLater':
			return dictionary.getValue('Error.TryAgainLater', null, 'Prøv evt. igen senere');
	}
};
