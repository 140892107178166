import { Dictionary } from 'Online/context/Dictionary';

export type StatusDictionaries = 'Active' | 'ActiveFrom' | 'Completed' | 'Pending' | 'Loading' | 'Status';

export const _Status = (dictionary: Partial<Dictionary>, item: StatusDictionaries): string => {
	switch (item) {
		case 'Active':
			return dictionary.getValue('Status.Active', null, 'Aktiv');
		case 'ActiveFrom':
			return dictionary.getValue('Status.ActiveFrom', null, 'Aktiv fra');
		case 'Completed':
			return dictionary.getValue('Status.Completed', null, 'Gennemført');
		case 'Pending':
			return dictionary.getValue('Status.Pending', null, 'Afventer');
		case 'Loading':
			return dictionary.getValue('Status.Loading', null, 'Indlæser...');
		case 'Status':
			return dictionary.getValue('Status', null, 'Status');
	}
};
