import { VFC } from 'react';
import { Heading, Paragraph, Picture } from 'shared';
import styles from './USPItem.module.scss';

type USPItemProps = {
	icon: Image;
	heading: string;
	body: string;
};
export const USPItem: VFC<USPItemProps> = ({ icon, heading, body }) => {
	return (
		<div className={styles.USPItem}>
			<Picture {...icon} className={styles.USPItem_icon} />
			<Heading headingLevel="h3" style="lg">
				{heading}
			</Heading>
			<Paragraph>{body}</Paragraph>
		</div>
	);
};
