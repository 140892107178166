import classNames from 'classnames';
import { Container, Picture, PictureProps, RichText } from 'shared';
import { toKebabCase } from 'utilities';
import styles from './ProductSection.module.scss';

export interface ProductSectionProps {
	content?: string;
	image?: PictureProps;
	settings?: {
		properties: {
			flipped?: boolean;
			style?: 'Light' | 'Dark';
			mobileImageBackgroundColor?: ESKBackgroundColors;
		};
	};
}

export const ProductSection: React.FC<ProductSectionProps> = ({ content, image, settings }) => {
	const { flipped = false, style = 'Dark', mobileImageBackgroundColor } = settings?.properties || {};

	return (
		<div
			className={classNames(styles.ProductSection, {
				[styles.ProductSection___flipped]: flipped,
				[styles.ProductSection___padded]: mobileImageBackgroundColor,
				[`u-background-color--${toKebabCase(mobileImageBackgroundColor)}`]: mobileImageBackgroundColor,
			})}
		>
			<div className={styles.ProductSection_imageBackground} />
			<div
				className={classNames(
					styles.ProductSection_wrapper,
					style && styles[`ProductSection_wrapper___style${style}`],
				)}
			>
				<Container width="Large">
					<div className={styles.ProductSection_contentContainer}>
						{image && (
							<div className={styles.ProductSection_imageContainer}>
								<div className={styles.ProductSection_imageRatioContainer}>
									<Picture
										className={styles.ProductSection_image}
										url={image.url}
										properties={image.properties}
										sizes={image.sizes}
									/>
								</div>
							</div>
						)}

						<div className={styles.ProductSection_richContent}>
							<RichText style={style === 'Light' ? null : 'light'} content={content} />
						</div>
					</div>
				</Container>
			</div>
		</div>
	);
};
