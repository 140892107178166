export const capitalize = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);

export const capitalizeAllWords = (text: string): string => {
	return text
		.toLowerCase()
		.split(' ')
		.map(function (word) {
			return capitalize(word);
		})
		.join(' ');
};

export const toKebabCase = (str: string): string =>
	str &&
	str
		.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
		.map((x) => x.toLowerCase())
		.join('-');
