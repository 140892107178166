/* eslint-disable */
let scriptLoaded = false;
let usabillaFrameLoading = false;
let stepId: string = undefined;

export const UsabillaId = 'e9e9f96ce675';
export const UsabillaWidgetIdReceipt = '655f39cd21869b6b55211aab';
export const UsabillaWidgetIdFlow = '655f3838f02d2d73834648a7';

export const loadUsabillaScript = () => {
	if (scriptLoaded) {
		return;
	}
	scriptLoaded = true;
	window.usabilla ||
		(function () {
			var a = window,
				d = a.document,
				c = {},
				f = d.createElement('div'),
				h = !1,
				a = (a.usabilla = function () {
					(c.a = c.a || []).push(arguments);
				});
			a._ = c;
			c.ids = {};
			f.style.display = 'none';
			(function () {
				if (!d.body) return setTimeout(arguments.callee, 100);
				d.body.insertBefore(f, d.body.firstChild).id = 'usabilla';
				h = !0;
			})();
			a.load = function (a, g, k) {
				if (!c.ids[g]) {
					const e = (c.ids = {});
					e.url = '//' + a + '/' + g + '.js?s1';
					e.config = k;
					setTimeout(function () {
						if (!h) return setTimeout(arguments.callee, 100);
						let b = d.createElement('iframe'),
							a;
						b.id = 'usabilla-' + g;
						/MSIE[ ]+6/.test(navigator.userAgent) && (b.src = 'javascript:false');
						f.appendChild(b);
						try {
							b.contentWindow.document.open();
						} catch (c) {
							(e.domain = d.domain),
								(a = "javascript:var d=document.open();d.domain='" + e.domain + "';"),
								(b.src = a + 'void(0);');
						}
						try {
							const l = b.contentWindow.document;
							l.write(
								[
									"<!DOCTYPE html><html><head></head><body onload=\"var d = document;d.getElementsByTagName('head')[0].appendChild(d.createElement('script')).src='",
									e.url,
									'\'"></body></html>',
								].join(''),
							);
							l.close();
						} catch (m) {
							b.src =
								a +
								'd.write("' +
								loaderHtml().replace(/"/g, String.fromCharCode(92) + '"') +
								'");d.close();';
						}
						b.contentWindow.config = k;
						b.contentWindow.SCRIPT_ID = g;
					}, 0);
				}
			};
		})();
};

export const refreshUsabilla = (step: string) => {
	if (stepId === step || usabillaFrameLoading) {
		return;
	}
	usabillaFrameLoading = true;

	stepId = step;

	setTimeout(() => {
		removeDanglingIframes();
		window.usabilla.load('w.usabilla.com', 'e9e9f96ce675');
		setTimeout(() => {
			try {
				usabillaFrameLoading = false;
				// document.querySelector('.usabilla_live_button_container').style.zIndex = "10";
			} catch (err) {}
		}, 500);
	}, 1500);
};

const removeDanglingIframes = () => {
	try {
		const iframes = document.querySelectorAll('div[ub-in-page] iframe') as any;
		if (iframes?.length) {
			iframes.forEach((iframe) => {
				iframe.parentNode.removeChild(iframe);
			});
		}
	} catch (e) {
		// too bad
	}
};
