import { Dictionary } from 'Online/context/Dictionary';

export type CallMeBackFormDictionaries = 'YouWillBeCalledWithinTheDesiredTime' | 'AlreadyRegisteredNumber';
export const _CallMeBackForm = (dictionary: Partial<Dictionary>, item: CallMeBackFormDictionaries): string => {
	switch (item) {
		case 'YouWillBeCalledWithinTheDesiredTime':
			return dictionary.getValue(
				'CallMeBackForm.YouWillBeCalledWithinTheDesiredTime',
				null,
				'Tak for din henvendelse. Du vil blive ringet op indenfor det ønskede tidspunkt. Vi ser frem til at snakke med dig.',
			);

		case 'AlreadyRegisteredNumber':
			return dictionary.getValue(
				'CallMeBackForm.AlreadyRegisteredNumber',
				null,
				'Vi har allerede registreret, at vi skal ringe tilbage på nummeret.',
			);
	}
};
