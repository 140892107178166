import classNames from 'classnames';
import styles from './DescriptionListItem.module.scss';

export type DescriptionListItemStyle = 'large' | 'highlight';

export interface DescriptionListItemProps {
	className?: string;
	term: string;
	description: string;
	style?: DescriptionListItemStyle;
	decorator?: string;
}

export const DescriptionListItem: React.FC<DescriptionListItemProps> = ({
	className,
	term,
	description,
	style,
	decorator,
}) => {
	return (
		<div
			className={classNames(
				styles.DescriptionListItem,
				styles[`DescriptionListItem___${style}`],
				decorator && styles.DescriptionListItem___withDecorator,
				className,
			)}
		>
			<dt className={classNames(styles.DescriptionListItem_text, styles.DescriptionListItem_text___term)}>
				{term}
			</dt>
			<dd className={classNames(styles.DescriptionListItem_text, styles.DescriptionListItem_text___description)}>
				{description}
				{decorator && <span className={styles.DescriptionListItem_decorator}>{decorator}</span>}
			</dd>
		</div>
	);
};
