import { Container, RichText } from 'shared';
import { CheckmarkList } from './CheckmarkList';
import styles from './CheckmarkListModule.module.scss';

export interface CheckmarkListModuleProps {
	heading?: string;
	text?: string;
	lists: CheckmarkList[];
}

export const CheckmarkListModule: React.FC<CheckmarkListModuleProps> = ({ heading, text, lists }) => {
	return (
		<div className={styles.CheckmarkListModule}>
			<Container>
				{heading && <RichText content={heading} />}

				{text && <RichText content={text} />}
			</Container>

			<Container width="Full width mobile">
				<div className={styles.CheckmarkListModule_grid}>
					{lists.map((listData, index) => {
						const { list, heading, style } = listData ?? {};

						return <CheckmarkList key={index} list={list} heading={heading} style={style} />;
					})}
				</div>
			</Container>
		</div>
	);
};
