import classNames from 'classnames';
import { MasterCardCta } from 'components';
import { useContext, useEffect, useState } from 'react';
import { AttentionCard, Container, DescriptionList, DescriptionListProps, FactboxItemProps, Usabilla } from 'shared';
import { DictionaryContext } from '../../../../context/Dictionary';
import { _Actions, _MasterCard, _Units } from '../../../../dictionaryItems';
import { MasterCardConditions } from './MasterCardConditions';
import styles from './MasterCardSummary.module.scss';
import { PageContext } from 'context/PageContext';
import { UsabillaWidgetIdFlow } from '../../../../utilities/loadUsabillaScript';
export interface MasterCardSummaryProps {
	callbackPrevious: () => void;
	callbackContinue: () => void;
	conditionsHeading: string;
	conditionsText: string;
	governmentInsurance: string;
	masterCardAttentionIcon?: Image;
	paymentProviders: FactboxItemProps[];
	priceDetailsList: DescriptionListProps;
	travelDetailsList: DescriptionListProps;
	userDetailsList: DescriptionListProps;
}

export const MasterCardSummary: React.FC<MasterCardSummaryProps> = ({
	callbackPrevious,
	callbackContinue,
	conditionsHeading,
	conditionsText,
	governmentInsurance,
	masterCardAttentionIcon,
	paymentProviders,
	priceDetailsList,
	travelDetailsList,
	userDetailsList,
}) => {
	const dictionary = useContext(DictionaryContext);
	const { theme } = useContext(PageContext);
	const [conditionsAccepted, setConditionsAccepted] = useState(false);
	const [showAttentionCard, setShowAttentionCard] = useState(true);

	useEffect(() => {
		switch (theme) {
			case 'Sydbank-theme':
			case 'Dark-theme Sydbank-theme':
				setShowAttentionCard(false);
				break;
			default:
				setShowAttentionCard(true);
				break;
		}
	});

	return (
		<div className={classNames(styles.MasterCardSummary)}>
			<Container width="Medium">
				{userDetailsList && (
					<DescriptionList className={styles.MasterCardSummary_topList} {...userDetailsList} />
				)}
				{travelDetailsList && (
					<DescriptionList className={styles.MasterCardSummary_middleList} {...travelDetailsList} />
				)}
				{priceDetailsList && (
					<DescriptionList
						className={classNames(styles.MasterCardSummary_priceDetailsList)}
						{...priceDetailsList}
					/>
				)}
			</Container>
			<AttentionCard
				className={styles.MasterCardSummary_attentioncard}
				conditionsText={
					_MasterCard(dictionary, 'PriceIncludesInsuranceToGovernment') +
					` ${governmentInsurance} ` +
					_Units(dictionary, 'DanishCrowns')
				}
				image={masterCardAttentionIcon}
				cardType={{ type: 'disclaimer' }}
				cardSize={{ size: 'small' }}
			/>

			<MasterCardConditions
				conditionsAccepted={(accepted) => setConditionsAccepted(accepted)}
				heading={conditionsHeading}
				text={conditionsText}
				paymentProviders={paymentProviders}
				className={classNames(styles.MasterCardSummary_conditions)}
				theme={theme}
			/>
			{showAttentionCard && (
				<AttentionCard
					className={styles.MasterCardSummary_attentioncard}
					conditionsText={_MasterCard(dictionary, 'RightOfWithdrawalExpirationText')}
					image={masterCardAttentionIcon}
					cardType={{ type: 'disclaimer' }}
					cardSize={{ size: 'medium' }}
				/>
			)}
			<Container className={classNames(styles.MasterCardSummary_ctaWrapper)} width="Medium">
				{showAttentionCard && (
					<p className={styles.MasterCardSummary_explainerText}>
						{_MasterCard(dictionary, 'RightOfWithdrawalExplainer')}
					</p>
				)}
				<MasterCardCta
					className={styles.MasterCardSummary_cta}
					callbackPrevious={callbackPrevious}
					callbackContinue={callbackContinue}
					continueText={_Actions(dictionary, 'GoToPayment')}
					continueDisabled={!conditionsAccepted}
				/>
			</Container>
			<Usabilla step="3" usabillaWidgetId={UsabillaWidgetIdFlow} category={'mastercard_sales_' + theme} />
		</div>
	);
};
