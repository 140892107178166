import classNames from 'classnames';

export interface ErrorMessageProps {
	className?: string;
	id: string;
	children?: React.ReactNode;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ className, id, children }) => (
	<div id={id} className={classNames(className)}>
		{children}
	</div>
);
