import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import styles from './ScrollTransition.module.scss';

export interface ScrollTransitionProps {
	children: React.ReactElement;
}

export const ScrollTransition: React.FC<ScrollTransitionProps> = (props) => {
	const [isVisible, setVisible] = useState(false);
	const domRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const current = domRef.current;
		if (!current) return;

		if (typeof window.IntersectionObserver !== 'undefined') {
			const observer = new window.IntersectionObserver((entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						setVisible(entry.isIntersecting);
					}
				});
			});
			observer.observe(current);
			return () => observer.unobserve(current);
		}
	}, [domRef]);

	return (
		<div
			className={classNames(styles.ScrollTransition, isVisible && styles.ScrollTransition___visible)}
			ref={domRef}
		>
			{props.children}
		</div>
	);
};
