import classNames from 'classnames';
import { Logo } from 'components';
import { PageContext } from 'context/PageContext';
import { useContext } from 'react';
import { Container, FooterCard, FooterNavigation, Grid, GridCell, Heading } from 'shared';
import styles from './ColumnFooter.module.scss';
import stylesCard from '../../0-common/2-molecules/Cards/FooterCard/FooterCard.module.scss';

export const ColumnFooter: React.FC = () => {
	const pageContext = useContext(PageContext);
	const footer = pageContext.footer;
	// Add 1 for last column which is different from the columns in linkColumns
	const columnsSize = (footer.linkColumns?.length || 0) + 1;
	const desktopWidth: GridCellWidth = (((100 / columnsSize) | 0) + '') as GridCellWidth;

	return (
		<footer className={classNames(styles.Footer)} role="contentinfo">
			<div className={styles.Footer_primary}>
				<Container width="Large">
					<Grid className="u-grid--cell-h-no-padding" wrap>
						{footer.linkColumns &&
							footer.linkColumns.map((column, index) => (
								<GridCell desktopWidth={desktopWidth} className={styles.Footer_cell} key={index}>
									<Heading headingLevel={'h3'} className={styles.Footer_column_heading}>
										{column.header}
									</Heading>
									<FooterCard links={column.links} />
								</GridCell>
							))}

						<GridCell desktopWidth={desktopWidth} className={styles.Footer_cell}>
							<FooterCard>
								<Logo
									style={'Light'}
									logoImageLight={Object.keys(footer.logoImage).length > 0 && footer.logoImage}
									noMargins={Object.keys(footer.logoImage).length === 0 || true}
									description={footer.heading}
									logoUrl={footer.logoUrl?.url}
								/>
							</FooterCard>
							<FooterCard text={footer.companyInformation} />
							<FooterNavigation socialMediaLinks={footer.socialMediaLinks} />
						</GridCell>
					</Grid>
				</Container>
			</div>
			{footer.linkColumns && footer.policyLinks && (
				<div className={styles.Footer_secondary}>
					<Container width="Large">
						<FooterCard links={footer.policyLinks} className={stylesCard.FooterCard_secondary} />
					</Container>
				</div>
			)}
		</footer>
	);
};
