import classNames from 'classnames';
import { ComponentProps } from 'react';
import styles from './Select.module.scss';

export interface SelectProps {
	options: { value: string; content: string }[];
	id: string;
	className?: string;
	onChange?: ComponentProps<'select'>['onChange'];
}

export const Select: React.FC<SelectProps> = ({ options, id, onChange, className }) => {
	return (
		<div className={classNames(styles.Select, className)}>
			<select className={styles.Select_select} id={id} onChange={onChange}>
				{options.map((item, index) => {
					return (
						<option value={item.value} key={index} className={styles.Select_option}>
							{item.content}
						</option>
					);
				})}
			</select>
		</div>
	);
};
