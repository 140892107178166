import { ReactComponent as CheckmarkIcon } from 'public/icons/icon_checkmark.svg';
import { Heading, RichText } from 'shared';
import styles from './Infobox.module.scss';

export interface Infobox {
	heading?: string;
	text?: string;
}

export const Infobox: React.FC<Infobox> = ({ heading, text }) => {
	return (
		<div className={styles.Infobox}>
			<div className={styles.Infobox_iconWrapper}>
				<CheckmarkIcon className={styles.Infobox_icon} />
			</div>

			<div className={styles.Infobox_content}>
				{heading ? (
					<Heading headingLevel="h3" style="lg">
						{heading}
					</Heading>
				) : null}

				{text ? <RichText content={text} className={styles.Infobox_text} /> : null}
			</div>
		</div>
	);
};
