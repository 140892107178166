import classNames from 'classnames';
import styles from './Label.module.scss';

export interface LabelProps {
	className?: string;
	id: string;
	children: React.ReactNode;
	state?: {
		required?: boolean;
		disabled?: boolean;
		hasError?: boolean;
		isHidden?: boolean;
	};
	style?: 'normal' | 'bold';
}

export const Label: React.FC<LabelProps> = ({ className, id, children, state, style }) => (
	<label
		className={classNames(
			styles.FormLabel,
			{ [styles.hasError]: state?.hasError },
			{ [styles.isDisabled]: state?.disabled },
			styles?.[`FormLabel___${style}`],
			className,
		)}
		htmlFor={id}
	>
		<p> {children} </p>
		{state?.required ? (
			<span
				className={classNames(styles.FormLabel___required, state?.isHidden ? styles.FormLabel___hidden : '')}
				aria-hidden="true"
			>
				*
			</span>
		) : (
			''
		)}
	</label>
);
