import React from 'react';
import classNames from 'classnames';
import styles from './Paragraph.module.scss';

export interface ParagraphProps {
	children: React.ReactNode;
	className?: string;
}

export const Paragraph: React.FC<ParagraphProps> = ({ children, className }) => (
	<p className={classNames(styles.Paragraph, className)}>{children}</p>
);
