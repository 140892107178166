import { useEffect, useState } from 'react';
import { Button, Container, RichText, Select } from 'shared';
import { generateId } from 'utilities';
import styles from './LinkSelectorModule.module.scss';

export interface LinkSelectorModuleProps {
	heading?: string;
	text?: string;
	linkSelectorList: Array<
		UmbracoContent<{
			link: Link;
		}>
	>;
	buttonText: string;
	dropdownPlaceholder: string;
	label: string;
}

export const LinkSelectorModule: React.FC<LinkSelectorModuleProps> = ({
	heading,
	text,
	linkSelectorList,
	buttonText,
	dropdownPlaceholder,
	label,
}) => {
	//ToDo - Implement dictionary in Optimizely for global texts? - Hardcoded for now
	//const dictionary = useContext(DictionaryContext);
	const [selectValue, setSelectValue] = useState('');
	const [idValue, setIdValue] = useState('');

	useEffect(() => {
		setIdValue(`select-${generateId()}`);
	}, []);

	//const buttonLabel = _Actions(dictionary, 'NavigateTo');
	const buttonLabel = 'Navigate to';

	const selectOptions = linkSelectorList.map((item) => {
		const { url, title } = item.content.properties.link || { url: '', title: '' };

		return {
			value: url,
			content: title,
		};
	});

	const handleButtonClick = () => {
		if (!selectValue) {
			return;
		}

		window.location.assign(selectValue);
	};

	return (
		<Container>
			<div className={styles.LinkSelectorModule}>
				{heading ? <RichText content={heading} className={styles.LinkSelectorModule_heading} /> : null}
				{text ? <RichText content={text} className={styles.LinkSelectorModule_text} /> : null}

				<div className={styles.LinkSelectorModule_wrapper}>
					<label htmlFor={idValue} className={styles.LinkSelectorModule_label}>
						{label}
					</label>

					<Select
						id={idValue}
						options={[{ value: '', content: dropdownPlaceholder }, ...selectOptions]}
						className={styles.LinkSelectorModule_select}
						onChange={(e) => {
							setSelectValue(e.target.value);
						}}
					/>

					<Button
						className={styles.LinkSelectorModule_button}
						style="primary"
						disabled={!selectValue}
						onClick={handleButtonClick}
						aria-label={selectValue ? `${buttonLabel} ${selectValue}` : ''}
					>
						{buttonText}
					</Button>
				</div>
			</div>
		</Container>
	);
};
