import classNames from 'classnames';
import styles from './Link.module.scss';

export type LinkStyle = 'primary' | 'sm' | 'dash';

export interface LinkProps {
	children: React.ReactNode;
	ariaLabel?: string;
	className?: string;
	url?: string;
	asText?: boolean;
	style?: LinkStyle;
}

export const Link: React.FC<LinkProps> = ({ children, style, ariaLabel, className, url, asText }) => (
	<>
		{asText ? (
			<p className={classNames(styles.Link, styles[`Link___${style}`], className)}>{children}</p>
		) : (
			<a
				href={url}
				aria-label={ariaLabel}
				className={classNames(styles.Link, styles[`Link___${style}`], className)}
			>
				{children}
			</a>
		)}
	</>
);
