import { Dictionary } from 'Online/context/Dictionary';

export type PlaceholderDictionaries =
	| 'EnterEmail'
	| 'EnterName'
	| 'EnterPSKCustomerNumber'
	| 'EnterPhoneNumber'
	| 'EnterFirstName'
	| 'EnterLastName'
	| 'EnterAddress'
	| 'EnterYourStreetName'
	| 'EnterYourHouseNumber'
	| 'EnterYourZipCode'
	| 'EnterCityName'
	| 'EnterYourEmail'
	| 'EnterYourBirthday';

export const _Placeholder = (dictionary: Partial<Dictionary>, item: PlaceholderDictionaries): string => {
	switch (item) {
		case 'EnterEmail':
			return dictionary.getValue('Placeholder.EnterEmail', null, 'Indtast email');
		case 'EnterName':
			return dictionary.getValue('Placeholder.EnterName', null, 'Indtast navn');
		case 'EnterPSKCustomerNumber':
			return dictionary.getValue('Placeholder.EnterPSKCustomerNumber', null, 'Indtast kundenr');
		case 'EnterPhoneNumber':
			return dictionary.getValue('Placeholder.EnterPhoneNumber', null, 'Indtast telefonnummer');

		case 'EnterFirstName':
			return dictionary.getValue('Placeholder.EnterFirstName', null, 'Indtast dit fornavn');
		case 'EnterLastName':
			return dictionary.getValue('Placeholder.EnterLastName', null, 'Indtast dit efternavn');
		case 'EnterAddress':
			return dictionary.getValue('Placeholder.EneterAddress', null, 'Indtast din adresse');
		case 'EnterYourStreetName':
			return dictionary.getValue('Placeholder.EnterYourStreetName', null, 'Indtast navnet på vejen, du bor på ');
		case 'EnterYourHouseNumber':
			return dictionary.getValue(
				'Placeholder.EnterYourHouseNumber',
				null,
				'Indtast dit husnummer og evt. bogstave og etage',
			);
		case 'EnterYourZipCode':
			return dictionary.getValue('Placeholder.EnterYourZipCode', null, 'Indtast det postnummer, du bor i.');
		case 'EnterCityName':
			return dictionary.getValue('Placeholder.EnterCityName', null, 'Indtast navnet på byen, du bor i.');
		case 'EnterYourEmail':
			return dictionary.getValue(
				'Placeholder.EnterYourEmail',
				null,
				'Indtast din mail. Den skal indeholde et @.',
			);
		case 'EnterYourBirthday':
			return dictionary.getValue(
				'Placeholder.EnterYourBirthday',
				null,
				'Indtast din fødselsdag på denne måde - fx. 15-03-1980',
			);
	}
};
