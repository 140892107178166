import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Modal } from 'shared';
import { ReactComponent as QuestionIcon } from '../../../../../../public/icons/icon-question.svg';
import styles from './FormfieldHelpCta.module.scss';

export interface FormfieldHelpCtaProps {
	closeText: string;
	helpText: string;
	className?: string;
}

export const FormfieldHelpCta: React.FC<FormfieldHelpCtaProps> = ({ closeText, helpText, className }) => {
	const [infoModuleOpen, setInfoModuleOpen] = useState(false);
	const helpTextRef = useRef(null);

	useEffect(() => {
		helpText && infoModuleOpen && (helpTextRef.current.innerHTML = helpText);
	}, [helpTextRef, infoModuleOpen]);

	return (
		<div className={classNames(styles.FormfieldHelpCta, className)}>
			<QuestionIcon className={styles.FormfieldHelpCta_icon} onClick={() => setInfoModuleOpen(true)} />
			{infoModuleOpen && (
				<Modal handleClose={() => setInfoModuleOpen(false)} style="simple" closeText={closeText}>
					{helpText && <p className={styles.FormfieldHelpCta_modalText} ref={helpTextRef} />}
				</Modal>
			)}
		</div>
	);
};
