import axios, { AxiosInstance } from 'axios';
// import getConfig from 'next/config';
import { axiosInstance } from './axiosInstance';

// const { publicRuntimeConfig } = getConfig();

// const engagementApiUrl = publicRuntimeConfig.NEXT_PUBLIC_ENGAGEMENT_API_URL;

//Set up a .env file for this

const engagementApiUrl = process.env.NEXT_PUBLIC_ENGAGEMENT_API_URL;

const instance = axios.create({
	httpAgent: axiosInstance.defaults.httpAgent,
	httpsAgent: axiosInstance.defaults.httpsAgent,
	baseURL: engagementApiUrl,
});

export const getApiInstance = (themeName?: string, withCredentials?: boolean): AxiosInstance => {
	const header = themeName ? { 'x-site-name': themeName === 'Privatsikring' ? 'PSK' : 'Codan' } : '';

	instance.defaults.withCredentials = withCredentials;
	instance.defaults.headers = { 'Content-Type': 'application/json', ...header };
	return instance;
};

instance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response.status === 401) window.location.href = '/logud';
		throw { error: error };
	},
);
