import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { RichText } from 'shared';
import styles from './AccordionItem.module.scss';

export type AccordionItemProps = {
	className?: string;
	title: string;
	htmlContent: string;
	openByDefault?: boolean;
	id: string;
	toggle?: (id: string, state: boolean) => void;
	isOpen?: boolean;
};

export const AccordionItem: React.FC<AccordionItemProps> = ({
	className,
	title,
	htmlContent,
	openByDefault = false,
	id,
	toggle,
	isOpen,
}) => {
	const [openState, setOpenState] = useState<boolean>(null);

	useEffect(() => {
		const state = isOpen === null ? openByDefault : isOpen;
		setOpenState(state);
	}, [isOpen, openByDefault]);
	return (
		<div className={classNames(styles.AccordionItem, className)}>
			<h3 className={styles.AccordionItem_triggerWrapper}>
				<button
					onClick={() => toggle(id, openState)}
					aria-expanded={openState}
					className={styles.AccordionItem_trigger}
					aria-controls={`panel-${id}`}
					id={`trigger-${id}`}
				>
					<span className={styles.AccordionItem_title}>
						{title}
						<span className={styles.AccordionItem_icon}>
							<span></span>
							<span></span>
						</span>
					</span>
				</button>
			</h3>
			<div
				id={`panel-${id}`}
				role="region"
				aria-labelledby={`trigger-${id}`}
				className={styles.AccordionItem_panel}
				hidden={!openState}
			>
				<RichText content={htmlContent} className={styles.AccordionItem_content} />
			</div>
		</div>
	);
};
