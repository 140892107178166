import { PageType } from 'components';
import { Page as PageContent, PageContext } from 'context/PageContext';
import { ThemeContext } from 'context/ThemeContext';
import React from 'react';
import { Provider } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { optimizelyContentApi } from 'services';
import { PageLoader } from 'shared';
import useSWR from 'swr';
import { store } from './store/store';
import './styles/globals.scss';
import { loadUsabillaScript } from './utilities/loadUsabillaScript';

const App = () => {
	const location = useLocation();
	const { data, error, isLoading } = useSWR(location.pathname, optimizelyContentApi);
	const persistor = persistStore(store);

	// TODO KLK should get this from GTM, when GTM is setup
	loadUsabillaScript();
	//ToDo - Add focal point again
	//ToDo - Move all static images (arrows) to Opti & fix links

	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ThemeContext themeName={data && data.theme}>
					{error && <div>Error fetching content. Import error card.</div>}
					{isLoading && <PageLoader />}
					{data && (
						<PageContext.Provider value={new PageContent(data)}>
							<PageType />
						</PageContext.Provider>
					)}
				</ThemeContext>
			</PersistGate>
		</Provider>
	);
};

export default App;
