const pad = (number: number) => (number < 10 ? `0${number}` : number);

const convertDate = (date) => new Date(date);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const convertToUSDate = (date: string) => {
	const day = date.substring(0, 2);
	const month = date.substring(3, 5);
	const year = date.substring(6);
	const USDate = `${month}/${day}/${year}`;

	return new Date(USDate);
};
export const formatDate = (dateString: Date, format: string): string => {
	let formattedDateString = format;

	const jsDate = convertDate(dateString);

	const dd = pad(jsDate.getUTCDate()).toString();
	const mm = pad(jsDate.getUTCMonth() + 1).toString();
	const yyyy = jsDate.getUTCFullYear().toString();

	formattedDateString = formattedDateString.replace('dd', dd);
	formattedDateString = formattedDateString.replace('mm', mm);
	formattedDateString = formattedDateString.replace('yyyy', yyyy);

	return formattedDateString;
};

export const formatTime = (dateString: Date, format: string): string => {
	let formattedTimeString = format;

	const jsDate = convertDate(dateString);

	const hh = pad(jsDate.getHours()).toString();
	const mm = pad(jsDate.getMinutes()).toString();

	formattedTimeString = formattedTimeString.replace('hh', hh);
	formattedTimeString = formattedTimeString.replace('mm', mm);

	return formattedTimeString;
};

export const extractTime = (dateString: string): number => {
	const event = new Date(dateString);
	return event.getHours();
};

export const formatTimeOption = (time: number): string => {
	return time.toString().padStart(2, '0') + ':00';
};

export const getTomorrowDate = (): string => {
	const today = new Date();
	const tomorrow = new Date(today.setDate(today.getDate() + 1));
	const tomorrowMonth = (tomorrow.getMonth() + 1).toString().padStart(2, '0');
	const tomorrowDay = tomorrow.getDate().toString().padStart(2, '0');

	return `${tomorrow.getFullYear()}-${tomorrowMonth}-${tomorrowDay}`;
};

export const getSixMonthBackDate = (): string => {
	const today = new Date();
	const sixMonthBack = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate());

	return formatDate(sixMonthBack, 'yyyy-mm-dd');
};

export const getInputDateLimit = (fromDate: string, monthLimit: number): string => {
	const startDate = new Date(fromDate);
	const dateLimit = new Date(startDate.getFullYear(), startDate.getMonth() + monthLimit, startDate.getDate());
	const dateLimitMonth = (dateLimit.getMonth() + 1).toString().padStart(2, '0');
	const dateLimitDay = dateLimit.getDate().toString().padStart(2, '0');

	return `${dateLimit.getFullYear()}-${dateLimitMonth}-${dateLimitDay}`;
};

export const getDanishDate = (date: Date): string => {
	return date.toLocaleString('DA-DK', {
		day: 'numeric',
		month: 'short',
		year: 'numeric',
	});
};
