import classNames from 'classnames';
import styles from './RichText.module.scss';
export interface RichTextProps {
	content: string;
	className?: string;
	style?: 'center' | 'petite' | 'large' | 'light';
}

export const RichText: React.FC<RichTextProps> = ({ content, className, style }) => {
	return (
		<div
			className={classNames(styles.RichText, 'u-rte', styles[`RichText___${style}`], className)}
			dangerouslySetInnerHTML={{ __html: content }}
		/>
	);
};
