import classNames from 'classnames';
import { ReactComponent as CheckmarkIcon } from 'public/icons/icon-checkmark.svg';
import { Heading, RichText } from 'shared';
import styles from './CheckmarkListItem.module.scss';

export interface CheckmarkListItemProps {
	heading?: string;
	text?: string;
}

export const CheckmarkListItem: React.FC<CheckmarkListItemProps> = ({ heading, text }) => {
	return (
		<li className={classNames(styles.CheckmarkListItem)}>
			<CheckmarkIcon className={styles.CheckmarkListItem_icon} />

			<div className={styles.CheckmarkListItem_content}>
				{heading ? (
					<Heading headingLevel="h3" className={styles.CheckmarkListItem_itemHeading}>
						{heading}
					</Heading>
				) : null}

				{text ? <RichText content={text} className={styles.CheckmarkListItem_text} /> : null}
			</div>
		</li>
	);
};
