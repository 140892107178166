import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';

const app = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);
app.render(
	<Router>
		<App />
	</Router>,
);
