import { imageSizesConfig } from 'utilities';

interface ImageProps extends Image {
	width: number;
	height?: number;
	format?: 'webp';
	imageFocalPoint?;
}

export const GetImageUrl = ({ url, imageFocalPoint, width, height = 0, format }: ImageProps): string => {
	if (!url) return '';

	//Handle base64 encoded images
	if (url.indexOf('base64') >= 0) return url;

	let imageUrl = `${url}?width=${width}`;

	if (height > 0 && imageFocalPoint)
		imageUrl = `${imageUrl}&height=${height}&mode=crop&center=${imageFocalPoint.y
			.toString()
			.replace(',', '.')},${imageFocalPoint.x.toString().replace(',', '.')}`;

	if (format) imageUrl = `${imageUrl}&format=webp`;

	return imageUrl;
};

export const GetSrcSetString = ({
	url,
	imageFocalPoint,
	format,
	aspectRatio,
}: {
	url: string;
	imageFocalPoint: { y: number; x: number };
	format?: 'webp';
	aspectRatio?: number;
}): string => {
	const { imageSizes } = imageSizesConfig.images;

	let string = '';

	imageSizes.map((width) => {
		let height = 0;

		if (aspectRatio) {
			height = aspectRatio * width;
		}

		return (string += `${GetImageUrl({
			url,
			imageFocalPoint,
			height,
			width: width,
			format,
		})} ${width}w, `);
	});

	return string.substring(0, string.length - 2);
};
