import classNames from 'classnames';
import { ComponentProps } from 'react';
import styles from './Button.module.scss';

export interface ButtonProps {
	children: React.ReactNode;
	size?: 'small' | 'medium';
	style?: 'primary' | 'secondary' | 'link';
	title?: string;
	className?: string;
	type?: 'button' | 'submit' | 'reset';
	disabled?: boolean;
	onClick?: ComponentProps<'button'>['onClick'];
}

export const Button: React.FC<ButtonProps> = ({
	children,
	size,
	style,
	title,
	className,
	type,
	disabled,
	onClick,
	...rest
}) => {
	return (
		<button
			type={type || 'button'}
			title={title}
			className={classNames(styles.Button, styles[`Button___${style}`], styles[`Button___${size}`], className)}
			disabled={disabled}
			onClick={onClick}
			{...rest}
		>
			{children}
		</button>
	);
};
