import classNames from 'classnames';

export interface ContainerProps {
	children: React.ReactNode;
	className?: string;
	width?: ContainerWidth;
}

export const Container: React.FC<ContainerProps> = ({ children, className, width }) => {
	const HandleContainerClass = () => {
		if (width == 'Full width') return 'u-container--fullWidth';
		if (width == 'Full width mobile') return 'u-container--fullWidth-mobile ';
		if (width == 'X large') return 'u-container--xl';
		if (width == 'Large') return 'u-container--large';
		if (width == 'Medium') return 'u-container--narrow';
		if (width == 'Small') return 'u-container--slim';
		return 'u-container';
	};
	return <div className={classNames(HandleContainerClass(), className)}>{children}</div>;
};
