import { Dictionary } from 'Online/context/Dictionary';

export type NoticesDictionaries =
	| 'Note'
	| 'ReceivedChangesWillContact'
	| 'NotRequired'
	| 'InactiveTitle'
	| 'Inactive5minWarning';

export const _Notices = (dictionary: Partial<Dictionary>, item: NoticesDictionaries): string => {
	switch (item) {
		case 'Note':
			return dictionary.getValue('Notices.Note', null, 'Bemærk!');
		case 'ReceivedChangesWillContact':
			return dictionary.getValue(
				'Notices.ReceivedChangesWillContact',
				null,
				'Vi har modtaget dine ønsker til ændringer og kontakter dig hurtigst muligt',
			);
		case 'NotRequired':
			return dictionary.getValue('Notices.NotRequired', null, 'Ikke krævet');
		case 'InactiveTitle':
			return dictionary.getValue('Notices.InactiveTitle', null, 'Er du stadig aktiv?');
		case 'Inactive5minWarning':
			return dictionary.getValue(
				'Notices.Inactive5minWarning',
				null,
				'Klik fortsæt hvis du stadig er aktiv, ellers bliver du automatisk logget ud om 5 minutter',
			);
	}
};
