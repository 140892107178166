import { ContentPage, MasterCardPageAPI } from 'components';
import { PageContext } from 'context/PageContext';
import { useContext } from 'react';

export const pages = {
	FrontPage: ContentPage,
	MastercardContainerPage: ContentPage,
	CallMeBackPage: ContentPage,
	TravelSalesPage: MasterCardPageAPI,
	MastercardPage: MasterCardPageAPI,
	TravelSalesLandingPage: ContentPage,
};

export const PageType: React.FC<PageModel> = (props) => {
	const pageContext = useContext(PageContext);
	let pageType = pageContext.pageType;
	if (typeof pages[pageType] === 'undefined') {
		return <div>{`Page type [${pageType}] is missing a React Component. Check /components/Page.tsx`}</div>;
	}

	const DynamicPageTemplate = pages[pageType];

	return <DynamicPageTemplate />;
};
