import { Dictionary } from 'Online/context/Dictionary';

export type ActionsDictionaries =
	| 'Back'
	| 'CancelAndClose'
	| 'ChooseBank'
	| 'ChooseBankBranch'
	| 'Close'
	| 'CloseWindow'
	| 'Continue'
	| 'GoToPayment'
	| 'Next'
	| 'OrderCall'
	| 'ReasonForCall'
	| 'ReadMore'
	| 'To'
	| 'WriteSearchWord'
	| 'NavigateTo';

export const _Actions = (dictionary: Partial<Dictionary>, item: ActionsDictionaries): string => {
	switch (item) {
		case 'Back':
			return dictionary.getValue('Actions.Back', null, 'Tilbage');
		case 'CancelAndClose':
			return dictionary.getValue('Actions.CancelAndClose', null, 'Annuller og luk');
		case 'ChooseBank':
			return dictionary.getValue('Actions.ChoseBank', null, 'Vælg bank');
		case 'ChooseBankBranch':
			return dictionary.getValue('Actions.ChooseBankBranch', null, 'Vælg afdeling');
		case 'Close':
			return dictionary.getValue('Actions.Close', null, 'Luk');
		case 'CloseWindow':
			return dictionary.getValue('Actions.CloseWindow', null, 'Luk vinduet');
		case 'Continue':
			return dictionary.getValue('Actions.Continue', null, 'Fortsæt');
		case 'GoToPayment':
			return dictionary.getValue('Actions.GoToPayment', null, 'Gå til betaling');
		case 'Next':
			return dictionary.getValue('Actions.Next', null, 'Næste');
		case 'OrderCall':
			return dictionary.getValue('Actions.OrderCall', null, 'Bestil opringning');
		case 'ReasonForCall':
			return dictionary.getValue('Actions.ReasonForCall', null, 'Vælg grund til opringning');
		case 'ReadMore':
			return dictionary.getValue('Actions.ReadMore', null, 'Læs mere');
		case 'To':
			return dictionary.getValue('Actions.To', null, 'Til');
		case 'WriteSearchWord':
			return dictionary.getValue('Actions.WriteSearchWord', null, 'Skriv søgeord');
		case 'NavigateTo':
			return dictionary.getValue('Action.NavigateTo', undefined, 'Navigate to');
	}
};
