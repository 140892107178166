import classNames from 'classnames';
import { ChangeEvent } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { FormfieldHelpCta, Label } from 'shared';
import styles from './FormfieldCheckbox.module.scss';

export interface FormfieldCheckboxProps {
	callback?: (event: boolean) => void;
	className?: string;
	defaultChecked?: boolean;
	defaultValue?: string;
	id: string;
	label: string;
	multiline?: boolean;
	name?: string;
	fieldGroup?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	register?: UseFormRegister<any>;
	state?: {
		required?: boolean;
		disabled?: boolean;
		hasError?: boolean;
	};
	style?: 'button';
	helpCtaCloseText?: string;
	helpCtaText?: string;
}

export const FormfieldCheckbox: React.FC<FormfieldCheckboxProps> = ({
	callback,
	className,
	fieldGroup,
	register,
	state,
	style,
	id,
	label,
	multiline,
	name,
	defaultChecked,
	helpCtaCloseText,
	helpCtaText,
}) => {
	const { required, disabled, hasError } = state ?? {};
	const labelState = {
		isHidden: false,
		disabled: state?.disabled,
		required: state?.required && !fieldGroup,
		hasError: state?.hasError,
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		callback((event.target as HTMLInputElement).checked);
	};

	const checkboxField = register && register(name, { required });

	return (
		<div
			className={classNames(
				styles.FormfieldCheckbox,
				styles[`FormfieldCheckbox___${style}`],
				className,
				{ [styles.hasError]: hasError },
				{ [styles.isDisabled]: disabled },
				{ [styles.FormfieldCheckbox___multiline]: multiline === true },
			)}
		>
			<input
				type="checkbox"
				className={classNames(styles.FormfieldCheckbox_input, styles.Input___choice, {
					[styles.hasError]: hasError,
				})}
				name={name}
				id={id}
				defaultChecked={defaultChecked}
				disabled={disabled}
				{...(register && checkboxField)}
				onChange={(e) => {
					register ? checkboxField.onChange(e) : null;
					callback ? handleChange(e) : null;
				}}
			/>
			<Label id={id} className={styles.FormfieldCheckbox_label} state={labelState}>
				{label}
			</Label>
			{helpCtaCloseText && (
				<FormfieldHelpCta
					// more general props for this text since this is not specifically mastercard
					closeText={helpCtaCloseText}
					helpText={helpCtaText}
				/>
			)}
		</div>
	);
};
