export const formatDayDate = (dateString: string, today: string, tomorrow: string): string => {
	const date = new Date(dateString);
	const todayDate = new Date();

	if (
		date.getFullYear() == todayDate.getFullYear() &&
		date.getMonth() == todayDate.getMonth() &&
		date.getDate() == todayDate.getDate()
	) {
		return today;
	}

	if (
		date.getFullYear() == todayDate.getFullYear() &&
		date.getMonth() == todayDate.getMonth() &&
		date.getDate() == todayDate.getDate() + 1
	) {
		return tomorrow;
	}

	return date.toLocaleDateString('DA-DK', { weekday: 'short' }) + ' ' + date.getDate() + '/' + (date.getMonth() + 1);
};
