// import classNames from 'classnames';
import styles from './Usabilla.module.scss';
import { refreshUsabilla } from '../../../../utilities/loadUsabillaScript';

export interface UsabillaProps {
	step: string;
	usabillaWidgetId: string;
	category: string;
}

export const Usabilla: React.FC<UsabillaProps> = ({ step, usabillaWidgetId, category }) => {
	refreshUsabilla(step);

	return (
		<div className={styles.Usabilla}>
			<div ub-in-page={usabillaWidgetId} ub-in-page-category={category}></div>
		</div>
	);
};
