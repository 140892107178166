import { useEffect, useRef, useState } from 'react';
import styles from './MasterCardContent.module.scss';

export interface MasterCardContentProps {
	heading?: string;
	text?: string;
	additionalText?: string;
}

export const MasterCardContent: React.FC<MasterCardContentProps> = ({ heading, text, additionalText }) => {
	const headingRef = useRef(null);
	const textRef = useRef(null);
	const additionalTextRef = useRef(null);

	useEffect(() => {
		heading && (headingRef.current.innerHTML = heading);
		text && (textRef.current.innerHTML = text);
		additionalText && (additionalTextRef.current.innerHTML = additionalText);
	}, [headingRef, textRef, additionalTextRef]);

	return (
		<div className={styles.MasterCardContent}>
			{heading && <p className={styles.MasterCardContent_heading} ref={headingRef} />}
			{text && <p className={styles.MasterCardContent_text} ref={textRef} />}
			{additionalText && <p className={styles.MasterCardContent_additionalText} ref={additionalTextRef} />}
		</div>
	);
};
