export const ISOCountryList = [
	{
		name: 'Afghanistan',
		alpha3: 'AFG',
		countrycode: '004',
	},
	{
		name: 'Åland Islands',
		alpha3: 'ALA',
		countrycode: '248',
	},
	{
		name: 'Albania',
		alpha3: 'ALB',
		countrycode: '008',
	},
	{
		name: 'Algeria',
		alpha3: 'DZA',
		countrycode: '012',
	},
	{
		name: 'American Samoa',
		alpha3: 'ASM',
		countrycode: '016',
	},
	{
		name: 'Andorra',
		alpha3: 'AND',
		countrycode: '020',
	},
	{
		name: 'Angola',
		alpha3: 'AGO',
		countrycode: '024',
	},
	{
		name: 'Anguilla',
		alpha3: 'AIA',
		countrycode: '660',
	},
	{
		name: 'Antarctica',
		alpha3: 'ATA',
		countrycode: '010',
	},
	{
		name: 'Antigua and Barbuda',
		alpha3: 'ATG',
		countrycode: '028',
	},
	{
		name: 'Argentina',
		alpha3: 'ARG',
		countrycode: '032',
	},
	{
		name: 'Armenia',
		alpha3: 'ARM',
		countrycode: '051',
	},
	{
		name: 'Aruba',
		alpha3: 'ABW',
		countrycode: '533',
	},
	{
		name: 'Australia',
		alpha3: 'AUS',
		countrycode: '036',
	},
	{
		name: 'Austria',
		alpha3: 'AUT',
		countrycode: '040',
	},
	{
		name: 'Azerbaijan',
		alpha3: 'AZE',
		countrycode: '031',
	},
	{
		name: 'Bahamas',
		alpha3: 'BHS',
		countrycode: '044',
	},
	{
		name: 'Bahrain',
		alpha3: 'BHR',
		countrycode: '048',
	},
	{
		name: 'Bangladesh',
		alpha3: 'BGD',
		countrycode: '050',
	},
	{
		name: 'Barbados',
		alpha3: 'BRB',
		countrycode: '052',
	},
	{
		name: 'Belarus',
		alpha3: 'BLR',
		countrycode: '112',
	},
	{
		name: 'Belgium',
		alpha3: 'BEL',
		countrycode: '056',
	},
	{
		name: 'Belize',
		alpha3: 'BLZ',
		countrycode: '084',
	},
	{
		name: 'Benin',
		alpha3: 'BEN',
		countrycode: '204',
	},
	{
		name: 'Bermuda',
		alpha3: 'BMU',
		countrycode: '060',
	},
	{
		name: 'Bhutan',
		alpha3: 'BTN',
		countrycode: '064',
	},
	{
		name: 'Bolivia (Plurinational State of)',
		alpha3: 'BOL',
		countrycode: '068',
	},
	{
		name: 'Bonaire, Sint Eustatius and Saba',
		alpha3: 'BES',
		countrycode: '535',
	},
	{
		name: 'Bosnia and Herzegovina',
		alpha3: 'BIH',
		countrycode: '070',
	},
	{
		name: 'Botswana',
		alpha3: 'BWA',
		countrycode: '072',
	},
	{
		name: 'Bouvet Island',
		alpha3: 'BVT',
		countrycode: '074',
	},
	{
		name: 'Brazil',
		alpha3: 'BRA',
		countrycode: '076',
	},
	{
		name: 'British Indian Ocean Territory',
		alpha3: 'IOT',
		countrycode: '086',
	},
	{
		name: 'Brunei Darussalam',
		alpha3: 'BRN',
		countrycode: '096',
	},
	{
		name: 'Bulgaria',
		alpha3: 'BGR',
		countrycode: '100',
	},
	{
		name: 'Burkina Faso',
		alpha3: 'BFA',
		countrycode: '854',
	},
	{
		name: 'Burundi',
		alpha3: 'BDI',
		countrycode: '108',
	},
	{
		name: 'Cabo Verde',
		alpha3: 'CPV',
		countrycode: '132',
	},
	{
		name: 'Cambodia',
		alpha3: 'KHM',
		countrycode: '116',
	},
	{
		name: 'Cameroon',
		alpha3: 'CMR',
		countrycode: '120',
	},
	{
		name: 'Canada',
		alpha3: 'CAN',
		countrycode: '124',
	},
	{
		name: 'Cayman Islands',
		alpha3: 'CYM',
		countrycode: '136',
	},
	{
		name: 'Central African Republic',
		alpha3: 'CAF',
		countrycode: '140',
	},
	{
		name: 'Chad',
		alpha3: 'TCD',
		countrycode: '148',
	},
	{
		name: 'Chile',
		alpha3: 'CHL',
		countrycode: '152',
	},
	{
		name: 'China',
		alpha3: 'CHN',
		countrycode: '156',
	},
	{
		name: 'Christmas Island',
		alpha3: 'CXR',
		countrycode: '162',
	},
	{
		name: 'Cocos (Keeling) Islands',
		alpha3: 'CCK',
		countrycode: '166',
	},
	{
		name: 'Colombia',
		alpha3: 'COL',
		countrycode: '170',
	},
	{
		name: 'Comoros',
		alpha3: 'COM',
		countrycode: '174',
	},
	{
		name: 'Congo',
		alpha3: 'COG',
		countrycode: '178',
	},
	{
		name: 'Congo, Democratic Republic of the',
		alpha3: 'COD',
		countrycode: '180',
	},
	{
		name: 'Cook Islands',
		alpha3: 'COK',
		countrycode: '184',
	},
	{
		name: 'Costa Rica',
		alpha3: 'CRI',
		countrycode: '188',
	},
	{
		name: "Côte d'Ivoire",
		alpha3: 'CIV',
		countrycode: '384',
	},
	{
		name: 'Croatia',
		alpha3: 'HRV',
		countrycode: '191',
	},
	{
		name: 'Cuba',
		alpha3: 'CUB',
		countrycode: '192',
	},
	{
		name: 'Curaçao',
		alpha3: 'CUW',
		countrycode: '531',
	},
	{
		name: 'Cyprus',
		alpha3: 'CYP',
		countrycode: '196',
	},
	{
		name: 'Czechia',
		alpha3: 'CZE',
		countrycode: '203',
	},
	{
		name: 'Denmark',
		alpha3: 'DNK',
		countrycode: '208',
	},
	{
		name: 'Djibouti',
		alpha3: 'DJI',
		countrycode: '262',
	},
	{
		name: 'Dominica',
		alpha3: 'DMA',
		countrycode: '212',
	},
	{
		name: 'Dominican Republic',
		alpha3: 'DOM',
		countrycode: '214',
	},
	{
		name: 'Ecuador',
		alpha3: 'ECU',
		countrycode: '218',
	},
	{
		name: 'Egypt',
		alpha3: 'EGY',
		countrycode: '818',
	},
	{
		name: 'El Salvador',
		alpha3: 'SLV',
		countrycode: '222',
	},
	{
		name: 'Equatorial Guinea',
		alpha3: 'GNQ',
		countrycode: '226',
	},
	{
		name: 'Eritrea',
		alpha3: 'ERI',
		countrycode: '232',
	},
	{
		name: 'Estonia',
		alpha3: 'EST',
		countrycode: '233',
	},
	{
		name: 'Eswatini',
		alpha3: 'SWZ',
		countrycode: '748',
	},
	{
		name: 'Ethiopia',
		alpha3: 'ETH',
		countrycode: '231',
	},
	{
		name: 'Falkland Islands (Malvinas)',
		alpha3: 'FLK',
		countrycode: '238',
	},
	{
		name: 'Faroe Islands',
		alpha3: 'FRO',
		countrycode: '234',
	},
	{
		name: 'Fiji',
		alpha3: 'FJI',
		countrycode: '242',
	},
	{
		name: 'Finland',
		alpha3: 'FIN',
		countrycode: '246',
	},
	{
		name: 'France',
		alpha3: 'FRA',
		countrycode: '250',
	},
	{
		name: 'French Guiana',
		alpha3: 'GUF',
		countrycode: '254',
	},
	{
		name: 'French Polynesia',
		alpha3: 'PYF',
		countrycode: '258',
	},
	{
		name: 'French Southern Territories',
		alpha3: 'ATF',
		countrycode: '260',
	},
	{
		name: 'Gabon',
		alpha3: 'GAB',
		countrycode: '266',
	},
	{
		name: 'Gambia',
		alpha3: 'GMB',
		countrycode: '270',
	},
	{
		name: 'Georgia',
		alpha3: 'GEO',
		countrycode: '268',
	},
	{
		name: 'Germany',
		alpha3: 'DEU',
		countrycode: '276',
	},
	{
		name: 'Ghana',
		alpha3: 'GHA',
		countrycode: '288',
	},
	{
		name: 'Gibraltar',
		alpha3: 'GIB',
		countrycode: '292',
	},
	{
		name: 'Greece',
		alpha3: 'GRC',
		countrycode: '300',
	},
	{
		name: 'Greenland',
		alpha3: 'GRL',
		countrycode: '304',
	},
	{
		name: 'Grenada',
		alpha3: 'GRD',
		countrycode: '308',
	},
	{
		name: 'Guadeloupe',
		alpha3: 'GLP',
		countrycode: '312',
	},
	{
		name: 'Guam',
		alpha3: 'GUM',
		countrycode: '316',
	},
	{
		name: 'Guatemala',
		alpha3: 'GTM',
		countrycode: '320',
	},
	{
		name: 'Guernsey',
		alpha3: 'GGY',
		countrycode: '831',
	},
	{
		name: 'Guinea',
		alpha3: 'GIN',
		countrycode: '324',
	},
	{
		name: 'Guinea-Bissau',
		alpha3: 'GNB',
		countrycode: '624',
	},
	{
		name: 'Guyana',
		alpha3: 'GUY',
		countrycode: '328',
	},
	{
		name: 'Haiti',
		alpha3: 'HTI',
		countrycode: '332',
	},
	{
		name: 'Heard Island and McDonald Islands',
		alpha3: 'HMD',
		countrycode: '334',
	},
	{
		name: 'Holy See',
		alpha3: 'VAT',
		countrycode: '336',
	},
	{
		name: 'Honduras',
		alpha3: 'HND',
		countrycode: '340',
	},
	{
		name: 'Hong Kong',
		alpha3: 'HKG',
		countrycode: '344',
	},
	{
		name: 'Hungary',
		alpha3: 'HUN',
		countrycode: '348',
	},
	{
		name: 'Iceland',
		alpha3: 'ISL',
		countrycode: '352',
	},
	{
		name: 'India',
		alpha3: 'IND',
		countrycode: '356',
	},
	{
		name: 'Indonesia',
		alpha3: 'IDN',
		countrycode: '360',
	},
	{
		name: 'Iran (Islamic Republic of)',
		alpha3: 'IRN',
		countrycode: '364',
	},
	{
		name: 'Iraq',
		alpha3: 'IRQ',
		countrycode: '368',
	},
	{
		name: 'Ireland',
		alpha3: 'IRL',
		countrycode: '372',
	},
	{
		name: 'Isle of Man',
		alpha3: 'IMN',
		countrycode: '833',
	},
	{
		name: 'Israel',
		alpha3: 'ISR',
		countrycode: '376',
	},
	{
		name: 'Italy',
		alpha3: 'ITA',
		countrycode: '380',
	},
	{
		name: 'Jamaica',
		alpha3: 'JAM',
		countrycode: '388',
	},
	{
		name: 'Japan',
		alpha3: 'JPN',
		countrycode: '392',
	},
	{
		name: 'Jersey',
		alpha3: 'JEY',
		countrycode: '832',
	},
	{
		name: 'Jordan',
		alpha3: 'JOR',
		countrycode: '400',
	},
	{
		name: 'Kazakhstan',
		alpha3: 'KAZ',
		countrycode: '398',
	},
	{
		name: 'Kenya',
		alpha3: 'KEN',
		countrycode: '404',
	},
	{
		name: 'Kiribati',
		alpha3: 'KIR',
		countrycode: '296',
	},
	{
		name: "Korea (Democratic People's Republic of)",
		alpha3: 'PRK',
		countrycode: '408',
	},
	{
		name: 'Korea, Republic of',
		alpha3: 'KOR',
		countrycode: '410',
	},
	{
		name: 'Kuwait',
		alpha3: 'KWT',
		countrycode: '414',
	},
	{
		name: 'Kyrgyzstan',
		alpha3: 'KGZ',
		countrycode: '417',
	},
	{
		name: "Lao People's Democratic Republic",
		alpha3: 'LAO',
		countrycode: '418',
	},
	{
		name: 'Latvia',
		alpha3: 'LVA',
		countrycode: '428',
	},
	{
		name: 'Lebanon',
		alpha3: 'LBN',
		countrycode: '422',
	},
	{
		name: 'Lesotho',
		alpha3: 'LSO',
		countrycode: '426',
	},
	{
		name: 'Liberia',
		alpha3: 'LBR',
		countrycode: '430',
	},
	{
		name: 'Libya',
		alpha3: 'LBY',
		countrycode: '434',
	},
	{
		name: 'Liechtenstein',
		alpha3: 'LIE',
		countrycode: '438',
	},
	{
		name: 'Lithuania',
		alpha3: 'LTU',
		countrycode: '440',
	},
	{
		name: 'Luxembourg',
		alpha3: 'LUX',
		countrycode: '442',
	},
	{
		name: 'Macao',
		alpha3: 'MAC',
		countrycode: '446',
	},
	{
		name: 'Madagascar',
		alpha3: 'MDG',
		countrycode: '450',
	},
	{
		name: 'Malawi',
		alpha3: 'MWI',
		countrycode: '454',
	},
	{
		name: 'Malaysia',
		alpha3: 'MYS',
		countrycode: '458',
	},
	{
		name: 'Maldives',
		alpha3: 'MDV',
		countrycode: '462',
	},
	{
		name: 'Mali',
		alpha3: 'MLI',
		countrycode: '466',
	},
	{
		name: 'Malta',
		alpha3: 'MLT',
		countrycode: '470',
	},
	{
		name: 'Marshall Islands',
		alpha3: 'MHL',
		countrycode: '584',
	},
	{
		name: 'Martinique',
		alpha3: 'MTQ',
		countrycode: '474',
	},
	{
		name: 'Mauritania',
		alpha3: 'MRT',
		countrycode: '478',
	},
	{
		name: 'Mauritius',
		alpha3: 'MUS',
		countrycode: '480',
	},
	{
		name: 'Mayotte',
		alpha3: 'MYT',
		countrycode: '175',
	},
	{
		name: 'Mexico',
		alpha3: 'MEX',
		countrycode: '484',
	},
	{
		name: 'Micronesia (Federated States of)',
		alpha3: 'FSM',
		countrycode: '583',
	},
	{
		name: 'Moldova, Republic of',
		alpha3: 'MDA',
		countrycode: '498',
	},
	{
		name: 'Monaco',
		alpha3: 'MCO',
		countrycode: '492',
	},
	{
		name: 'Mongolia',
		alpha3: 'MNG',
		countrycode: '496',
	},
	{
		name: 'Montenegro',
		alpha3: 'MNE',
		countrycode: '499',
	},
	{
		name: 'Montserrat',
		alpha3: 'MSR',
		countrycode: '500',
	},
	{
		name: 'Morocco',
		alpha3: 'MAR',
		countrycode: '504',
	},
	{
		name: 'Mozambique',
		alpha3: 'MOZ',
		countrycode: '508',
	},
	{
		name: 'Myanmar',
		alpha3: 'MMR',
		countrycode: '104',
	},
	{
		name: 'Namibia',
		alpha3: 'NAM',
		countrycode: '516',
	},
	{
		name: 'Nauru',
		alpha3: 'NRU',
		countrycode: '520',
	},
	{
		name: 'Nepal',
		alpha3: 'NPL',
		countrycode: '524',
	},
	{
		name: 'Netherlands',
		alpha3: 'NLD',
		countrycode: '528',
	},
	{
		name: 'New Caledonia',
		alpha3: 'NCL',
		countrycode: '540',
	},
	{
		name: 'New Zealand',
		alpha3: 'NZL',
		countrycode: '554',
	},
	{
		name: 'Nicaragua',
		alpha3: 'NIC',
		countrycode: '558',
	},
	{
		name: 'Niger',
		alpha3: 'NER',
		countrycode: '562',
	},
	{
		name: 'Nigeria',
		alpha3: 'NGA',
		countrycode: '566',
	},
	{
		name: 'Niue',
		alpha3: 'NIU',
		countrycode: '570',
	},
	{
		name: 'Norfolk Island',
		alpha3: 'NFK',
		countrycode: '574',
	},
	{
		name: 'North Macedonia',
		alpha3: 'MKD',
		countrycode: '807',
	},
	{
		name: 'Northern Mariana Islands',
		alpha3: 'MNP',
		countrycode: '580',
	},
	{
		name: 'Norway',
		alpha3: 'NOR',
		countrycode: '578',
	},
	{
		name: 'Oman',
		alpha3: 'OMN',
		countrycode: '512',
	},
	{
		name: 'Pakistan',
		alpha3: 'PAK',
		countrycode: '586',
	},
	{
		name: 'Palau',
		alpha3: 'PLW',
		countrycode: '585',
	},
	{
		name: 'Palestine, State of',
		alpha3: 'PSE',
		countrycode: '275',
	},
	{
		name: 'Panama',
		alpha3: 'PAN',
		countrycode: '591',
	},
	{
		name: 'Papua New Guinea',
		alpha3: 'PNG',
		countrycode: '598',
	},
	{
		name: 'Paraguay',
		alpha3: 'PRY',
		countrycode: '600',
	},
	{
		name: 'Peru',
		alpha3: 'PER',
		countrycode: '604',
	},
	{
		name: 'Philippines',
		alpha3: 'PHL',
		countrycode: '608',
	},
	{
		name: 'Pitcairn',
		alpha3: 'PCN',
		countrycode: '612',
	},
	{
		name: 'Poland',
		alpha3: 'POL',
		countrycode: '616',
	},
	{
		name: 'Portugal',
		alpha3: 'PRT',
		countrycode: '620',
	},
	{
		name: 'Puerto Rico',
		alpha3: 'PRI',
		countrycode: '630',
	},
	{
		name: 'Qatar',
		alpha3: 'QAT',
		countrycode: '634',
	},
	{
		name: 'Réunion',
		alpha3: 'REU',
		countrycode: '638',
	},
	{
		name: 'Romania',
		alpha3: 'ROU',
		countrycode: '642',
	},
	{
		name: 'Russian Federation',
		alpha3: 'RUS',
		countrycode: '643',
	},
	{
		name: 'Rwanda',
		alpha3: 'RWA',
		countrycode: '646',
	},
	{
		name: 'Saint Barthélemy',
		alpha3: 'BLM',
		countrycode: '652',
	},
	{
		name: 'Saint Helena, Ascension and Tristan da Cunha',
		alpha3: 'SHN',
		countrycode: '654',
	},
	{
		name: 'Saint Kitts and Nevis',
		alpha3: 'KNA',
		countrycode: '659',
	},
	{
		name: 'Saint Lucia',
		alpha3: 'LCA',
		countrycode: '662',
	},
	{
		name: 'Saint Martin (French part)',
		alpha3: 'MAF',
		countrycode: '663',
	},
	{
		name: 'Saint Pierre and Miquelon',
		alpha3: 'SPM',
		countrycode: '666',
	},
	{
		name: 'Saint Vincent and the Grenadines',
		alpha3: 'VCT',
		countrycode: '670',
	},
	{
		name: 'Samoa',
		alpha3: 'WSM',
		countrycode: '882',
	},
	{
		name: 'San Marino',
		alpha3: 'SMR',
		countrycode: '674',
	},
	{
		name: 'Sao Tome and Principe',
		alpha3: 'STP',
		countrycode: '678',
	},
	{
		name: 'Saudi Arabia',
		alpha3: 'SAU',
		countrycode: '682',
	},
	{
		name: 'Senegal',
		alpha3: 'SEN',
		countrycode: '686',
	},
	{
		name: 'Serbia',
		alpha3: 'SRB',
		countrycode: '688',
	},
	{
		name: 'Seychelles',
		alpha3: 'SYC',
		countrycode: '690',
	},
	{
		name: 'Sierra Leone',
		alpha3: 'SLE',
		countrycode: '694',
	},
	{
		name: 'Singapore',
		alpha3: 'SGP',
		countrycode: '702',
	},
	{
		name: 'Sint Maarten (Dutch part)',
		alpha3: 'SXM',
		countrycode: '534',
	},
	{
		name: 'Slovakia',
		alpha3: 'SVK',
		countrycode: '703',
	},
	{
		name: 'Slovenia',
		alpha3: 'SVN',
		countrycode: '705',
	},
	{
		name: 'Solomon Islands',
		alpha3: 'SLB',
		countrycode: '090',
	},
	{
		name: 'Somalia',
		alpha3: 'SOM',
		countrycode: '706',
	},
	{
		name: 'South Africa',
		alpha3: 'ZAF',
		countrycode: '710',
	},
	{
		name: 'South Georgia and the South Sandwich Islands',
		alpha3: 'SGS',
		countrycode: '239',
	},
	{
		name: 'South Sudan',
		alpha3: 'SSD',
		countrycode: '728',
	},
	{
		name: 'Spain',
		alpha3: 'ESP',
		countrycode: '724',
	},
	{
		name: 'Sri Lanka',
		alpha3: 'LKA',
		countrycode: '144',
	},
	{
		name: 'Sudan',
		alpha3: 'SDN',
		countrycode: '729',
	},
	{
		name: 'Suriname',
		alpha3: 'SUR',
		countrycode: '740',
	},
	{
		name: 'Svalbard and Jan Mayen',
		alpha3: 'SJM',
		countrycode: '744',
	},
	{
		name: 'Sweden',
		alpha3: 'SWE',
		countrycode: '752',
	},
	{
		name: 'Switzerland',
		alpha3: 'CHE',
		countrycode: '756',
	},
	{
		name: 'Syrian Arab Republic',
		alpha3: 'SYR',
		countrycode: '760',
	},
	{
		name: 'Taiwan, Province of China',
		alpha3: 'TWN',
		countrycode: '158',
	},
	{
		name: 'Tajikistan',
		alpha3: 'TJK',
		countrycode: '762',
	},
	{
		name: 'Tanzania, United Republic of',
		alpha3: 'TZA',
		countrycode: '834',
	},
	{
		name: 'Thailand',
		alpha3: 'THA',
		countrycode: '764',
	},
	{
		name: 'Timor-Leste',
		alpha3: 'TLS',
		countrycode: '626',
	},
	{
		name: 'Togo',
		alpha3: 'TGO',
		countrycode: '768',
	},
	{
		name: 'Tokelau',
		alpha3: 'TKL',
		countrycode: '772',
	},
	{
		name: 'Tonga',
		alpha3: 'TON',
		countrycode: '776',
	},
	{
		name: 'Trinidad and Tobago',
		alpha3: 'TTO',
		countrycode: '780',
	},
	{
		name: 'Tunisia',
		alpha3: 'TUN',
		countrycode: '788',
	},
	{
		name: 'Turkey',
		alpha3: 'TUR',
		countrycode: '792',
	},
	{
		name: 'Turkmenistan',
		alpha3: 'TKM',
		countrycode: '795',
	},
	{
		name: 'Turks and Caicos Islands',
		alpha3: 'TCA',
		countrycode: '796',
	},
	{
		name: 'Tuvalu',
		alpha3: 'TUV',
		countrycode: '798',
	},
	{
		name: 'Uganda',
		alpha3: 'UGA',
		countrycode: '800',
	},
	{
		name: 'Ukraine',
		alpha3: 'UKR',
		countrycode: '804',
	},
	{
		name: 'United Arab Emirates',
		alpha3: 'ARE',
		countrycode: '784',
	},
	{
		name: 'United Kingdom of Great Britain and Northern Ireland',
		alpha3: 'GBR',
		countrycode: '826',
	},
	{
		name: 'United States of America',
		alpha3: 'USA',
		countrycode: '840',
	},
	{
		name: 'United States Minor Outlying Islands',
		alpha3: 'UMI',
		countrycode: '581',
	},
	{
		name: 'Uruguay',
		alpha3: 'URY',
		countrycode: '858',
	},
	{
		name: 'Uzbekistan',
		alpha3: 'UZB',
		countrycode: '860',
	},
	{
		name: 'Vanuatu',
		alpha3: 'VUT',
		countrycode: '548',
	},
	{
		name: 'Venezuela (Bolivarian Republic of)',
		alpha3: 'VEN',
		countrycode: '862',
	},
	{
		name: 'Viet Nam',
		alpha3: 'VNM',
		countrycode: '704',
	},
	{
		name: 'Virgin Islands (British)',
		alpha3: 'VGB',
		countrycode: '092',
	},
	{
		name: 'Virgin Islands (U.S.)',
		alpha3: 'VIR',
		countrycode: '850',
	},
	{
		name: 'Wallis and Futuna',
		alpha3: 'WLF',
		countrycode: '876',
	},
	{
		name: 'Western Sahara',
		alpha3: 'ESH',
		countrycode: '732',
	},
	{
		name: 'Yemen',
		alpha3: 'YEM',
		countrycode: '887',
	},
	{
		name: 'Zambia',
		alpha3: 'ZMB',
		countrycode: '894',
	},
	{
		name: 'Zimbabwe',
		alpha3: 'ZWE',
		countrycode: '716',
	},
];
